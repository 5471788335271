@import "../styles/theme";
@import "../styles/viewports";

.searchSelect {
  position: relative;
  padding: 16px 12px 0 12px;
  background-color: var(--primary-background);
  max-height: 350px;
  overflow-y: auto;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);

  @include breakpoint(sm) {
    max-width: 100%;
  }

  .inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  
    input {
      flex: 1;
      height: 32px;
      background-color: var(--primary-background);
      margin: 12px;
      padding: 0;
      color: var(--primary-foreground);
      font-size: 16px;
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      border: 0;
  
      &:focus {
        outline: none;
      }
  
      &:disabled {
        background-color: $disabled;
        border: none;
      }
    }
  }
  
  .listContainer {
    margin: 0;
    padding: 0;
  
    .listItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 8px;
      margin: 8px 0;
      user-select: none;
      clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
    
      img.asset-logo {
        height: 32px;
        width: 32px;
        margin-right: 8px;
      }
    
      .tagInfo {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 16px 0 0;

        .tagWrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--text);
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.5px;
          margin-right: 8px;
          transition: color 1s ease-in-out;
        }

        .tagDesc {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.4px;
          opacity: 0.5;
          color: var(--text-lighter);
          transition: color 1s ease-in-out;
        }
      }

      .tagValue {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        .textMinor {
          margin: 0;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-align: right;
          letter-spacing: 0.4px;
          opacity: 0.5;
          color: var(--text-lighter);
          transition: color 1s ease-in-out;
        }
        
        .sumMinor {
          font-size: 16px;

          @include breakpoint(sm) {
            max-width: 295px;
          }

          @include breakpoint(xm) {
            max-width: 241px;
          }

          @include breakpoint(xs) {
            max-width: 180px;
          }

          @include breakpoint(xxs) {
            max-width: 126px;
          }
        }
      }

      &.isAvailable {
        &:hover,
        &.isActive {
          background-color: var(--background);
          cursor: pointer;
          transition: background-color .3s ease-in-out;
        }
      }
      &.isUnavailable {
        b,
        div,
        span {
          color: var(--text-lightest);
        }
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.searchSelect::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.searchSelect::-webkit-scrollbar-track {
  background: var(--primary-background);
  transition: background 1s ease-in-out;
}
.searchSelect::-webkit-scrollbar-thumb {
  background: var(--active-green);
  border-bottom-right-radius: 8px;
  transition: background 1s ease-in-out;
}

body.dark .searchSelect::-webkit-scrollbar-track {
  background: var(--primary-background);
}
body.dark .searchSelect::-webkit-scrollbar-thumb {
  background: var(--active-green);
}
