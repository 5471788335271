@import "../styles/theme";
@import "../styles/viewports";

div.riskpage {
  min-height: 100vh;
  background: var(--background); // new
  box-sizing: border-box; // Make the padding included in height calculation
  transition: background 1s ease-in-out;

  .contentInnerWrapper {
    margin: 0 auto;
    max-width: 776px;
    color: var(--text-lighter2);
    transition: color 1s ease-in-out;
    font-family: $font-family;
    padding: 184px 0 153px 0;
    transition: color .3s ease-in-out;

    @include breakpoint(lg) {
      padding: 138px 0 153px 0;
    }

    @include breakpoint(md) {
      padding: 104px 0 153px 0;
    }

    @include breakpoint(xm) {
      padding: 104px 0 367px 0;
    }
  }

  h1 {
    font-family: $font-family;
    margin-top: 0;
    margin-bottom: -8px;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.25px;
    color: var(--text);
    transition: color .3s ease-in-out;
  }

  h3 {
    font-family: $font-family;
    margin-top: 48px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: var(--text-lighter2);
    transition: color .3s ease-in-out;
  }

  a {
    color: var(--active-green);

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-top: 16px !important;
    margin-bottom: 24px !important;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.5px;

    &.version {
      margin-top: 48px !important;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.4px;
      color: var(--text);
      transition: color .3s ease-in-out;
    }
  }
}
