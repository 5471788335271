@import "../styles/theme.scss";

.balanceContainer {
  text-align: right;
  padding-bottom: 8px;
  span,
  a {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    color: var(--text);
    transition: color 1s ease-in-out;
  }
  a {
    font-weight: 700;
    color: var(--active-green);
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }
  }
}

.tokenInputContainer {
  height: 68px;
  padding: 13px 24px 13px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: var(--background);
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 24px), calc(100% - 24px) 100%, 0 100%);
  transition: background-color 1s ease-in-out;
  border-left: 4px solid var(--active-green);

  &.error {
    border-left: 4px solid $error;
  }

  &.disabled {
    color: var(--text-lighter);
    border-left: 2px solid rgba(40, 48, 55, var(--underline-opacity));
    opacity: .7;
    cursor: not-allowed;
    transition: border-left 1s ease-in-out, opacity 1s ease-in-out, background-color 1s ease-in-out;

    & > img {
      filter: grayscale(1);
      opacity: .7;
    }

    .boldText {
      color: var(--text-lighter);
      transition: color 1s ease-in-out;
    }
  }

  .tokenSymbolAndName {
    display: flex;
    flex-direction: column;
    margin-right: 16px;

    .smallText {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: var(--text);
      opacity: var(--input-subtext-opacity);
      transition: all 1s ease-in-out;
    }
  
    .boldText {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      transition: color 1s ease-in-out;
    }
  }

  img {
    vertical-align: text-bottom;
    height: 32px;
    width: 32px;
    margin-right: 8px;
  }

  .inputGroup {
    display: flex;
    flex: 1 1 60%;
    flex-direction: column;
    align-items: flex-end;
  
    input {
      height: 29px;
      margin: 0;
      padding-right: 0;
      border: 0;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      cursor: text;
      outline: none;
      text-align: right;
      background-color: transparent;
      color: var(--text);
      width: 100%;
      transition: all 1s ease-in-out;

      &::placeholder {
        color: var(--text-lighter);
      }
  
      &:focus {
        outline: none;
      }
  
      &:disabled {
        color: var(--text-lighter);
        cursor: not-allowed;
      }
    }

    .smallText {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: right;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      opacity: var(--input-subtext-opacity);
      color: var(--text);
      transition: all 1s ease-in-out;
    }
  }
}
