@import "../styles/theme";
@import "../styles/viewports";

.siteSettingsMenu {
  &.containerWrapper {
    position: relative;
    width: 299px;
    filter: drop-shadow(var(--shadow));
  }

  .container {
    background: var(--background-lightgrey); // new
    padding: 16px 24px 24px 16px;
    width: 299px;
    height: auto !important;
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 0;
    color: var(--text);
    transition: background 1s ease-in-out, color 1s ease-in-out;

    @include breakpoint(xl) {
      right: -66px;
    }

    @include breakpoint(lg) {
      right: -48px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.0015em;
      color: var(--text);
      transition: color .3s ease-in-out;

      .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        img,
        svg {
          height: 12px;
          width: 12px;
          cursor: pointer;

          path {
            fill: var(--text);
            transition: fill .3s ease-in-out;
          }
        }
      }
    }
  }

  .divider {
    background-color: #DFE2E5;
    transition: unset;
    height: 1px;
    width: 100%;
    margin: 12px 0 16px;
  }
  
  .sectionTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    color: #787F87;
    text-transform: uppercase;
  }
  
  .sectionItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    padding: 8px 24px 8px 16px;
    margin-top: 4px;
    margin-bottom: -8px;
    margin-left: -16px;
    margin-right: -24px;
    color: var(--text);
    cursor: pointer;
    background-color: transparent;
    transition: background-color 1s ease-in-out;

    &:hover {
      background-color: var(--background-lightgrey-hover);
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      margin-right: 9px;
      background-color: #fff;
      border-radius: 50%;

      &.transparent {
        background-color: transparent;
      }

      & > div {
        display: flex;
        align-items: center;
      }

      img,
      svg {
        height: 20px;
        width: 20px;

        path {
          fill: var(--text);
          transition: fill .3s ease-in-out;
        }
      }
    }

    .text {
      display: flex;
      align-items: center;
      width: calc(100% - 20px - 9px - 14px);
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      // color: var(--text);
      transition: color .3s ease-in-out;
    }

    .is-active {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 14px;
        width: 14px;
      }
    }
  }

  .add-token {
    img {
      height: 24px;
      width: 24px;
    }
  }
}
