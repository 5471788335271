@import "../styles/theme";
@import "../styles/viewports";

div.poolOverview {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  background: var(--background-lightgrey); // new
  box-shadow: var(--shadow);
  padding: 27px 24px 24px 24px;
  display: grid;
  grid-template-columns: 40% 60%;
  transition: background 1s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    background: var(--active-green);
    top: 30px;
    left: 0;
    width: 4px;
    height: 32px;
  }

  @include breakpoint(md) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    row-gap: 24px;
  }
  @include breakpoint(sm) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @include breakpoint(xs) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.tagged {
    padding: 32px 16px 25px 16px;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 4px;
  }

  .titleAndTag {
    display: flex;

    .absolute {
      position: absolute;
    }
  }

  h4.overview-title {
    font-family: $font-family;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 248px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: var(--text);
    margin-right: 16px;
    transition: color 1s ease-in-out;

    @include breakpoint(md) {
      max-width: 100%;
    }
  }

  .balance {
    margin-top: 5px;
    margin-bottom: 11px;
    color: var(--text-lighter);
    font-weight: 500;
    font-size: 12px;
    line-height: 15px !important;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    transition: color 1s ease-in-out;

    @include breakpoint(md) {
      margin-bottom: 16px;
    }

    .value {
      color: var(--active-green);
      font-size: 16px;
      font-weight: 700;
      line-height: 15px !important;
      letter-spacing: 0.4px;
      transition: color 1s ease-in-out;
    }
  }
  .tokens {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    @include breakpoint(md) {
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 0;
    }
  }

  div.token {
    display: flex;
    align-items: center;
    margin-right: 32px;
    color: var(--text);
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    transition: color 1s ease-in-out;

    @include breakpoint(xs) {
      margin-right: 16px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  img.tokenIcon {
    margin-right: 8px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  div.right {
    display: grid;

    @include breakpoint(md) {
      justify-content: space-between;
      row-gap: 24px;
      grid-template-columns: 100%;
    }

    .margin {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      min-width: 117px;
      padding: 0 21px 0 25px; // 24 + 1 = 25
      transition: border-left 1s ease-in-out;

      @include breakpoint(md) {
        margin-right: auto;
      }

      @include breakpoint(xs) {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 7px;

        @include breakpoint(xs) {
          margin-bottom: 0;
        }
      }

      &::before {
        content: "";
        position: absolute;
        background: var(--divider);
        top: 9px;
        left: 0;
        width: 1px;
        height: 24px;
      }
    }

    .poolInfo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      @include breakpoint(md) {
        width: 100%;
        justify-content: space-between;
      }

      @include breakpoint(xs) {
        flex-wrap: wrap;
      }

      img.tokenIcon {
        height: 20px;
        width: 20px;
      }
    }
    span.label {
      display: block;
      color: var(--text-lighter);
      font-weight: 500;
      font-size: 12px;
      line-height: 15px !important;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      transition: color 1s ease-in-out;
      white-space: nowrap;
    }

    span.value {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      color: var(--text);
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.0015em;
      text-transform: uppercase;
      transition: color 1s ease-in-out;

      &.percents {
        color: var(--active-green);
      }
    }

    .buttons {
      margin-top: 26px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include breakpoint(md) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
      }

      a {
        margin-right: 24px;
        text-decoration: none;

        @include breakpoint(xs) {
          margin-right: 16px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      button {
        color: var(--text);
        background-color: var(--secondary-button-active);
        transition: all .3s ease-in-out;

        @include breakpoint(xs) {
          width: 136px;
        }
      }
    }
  }
  .underline {
    border-bottom: 1px dotted var(--text);
  }

  .partnershipWith {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 -12px;
    padding-top: 15px;
    color: var(--text);
    font-family: $font-family;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px !important;
    letter-spacing: 0.4px;
    transition: color 1s ease-in-out;
    border-top: 1px solid rgba(40, 48, 55, var(--underline-opacity));

    @include breakpoint(md) {
      grid-column-end: 2;
    }

    .slogan {
      text-align: center;
    }

    img {
      height: 24px;
      margin-left: 12px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: var(--text);
      transition: color 1s ease-in-out;
      cursor: pointer;

      &:hover {
        .slogan {
          margin-bottom: -1px;
          border-bottom: 1px solid var(--text-lighter);
        }
      }
    }
  }
}

div.outdated {
  border-color: $yellow2;
}

div.poolOverview:first-child {
  margin-top: 0;
}
