@import "../styles/theme.scss";
@import "../styles/viewports";

button.button {
  border: none;
  color: var(--text);
  font-family: $font-family;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 1s ease-in-out, color 1s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    color: var(--text);
    background-color: var(--secondary-button) !important;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.medium {
    width: 160px;
  }

  &.large {
    padding: 0 32px 0 14px;
    width: 176px;
    min-height: 40px;
    font-size: 12px;
    line-height: 40px;
    letter-spacing: 0.1em;
    color: var(--text);
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
    background-color: var(--secondary-button-active);
    transition: all .3s ease-in-out;

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--secondary-button-active-max);
      color: var(--active-green);
      box-shadow: var(--shadow-hover);
    }

    @include breakpoint(md) {
      width: 140px;
    }
  }

  &.medium {
    padding: 0 32px 0 14px;
    min-height: 40px;
    font-size: 12px;
    line-height: 40px;
    letter-spacing: 0.1em;
    color: var(--text);
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
    background-color: var(--secondary-button-active-max);
    transition: all .3s ease-in-out;

    &:enabled:hover,
    &:enabled:focus {
      color: var(--active-green);
      box-shadow: var(--shadow-hover);
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.temporary {
    background-color: rgba(240, 142, 27, 0.2);

    &:enabled:hover,
    &:enabled:focus {
      color: #f08e1b;
      background-color: rgba(240, 142, 27, 0.3);
    }
  }

  &.extra-large {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px !important;
    padding-right: 32px !important;
    height: 42px;
    border-radius: 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1.25px;
    background: var(--active-green);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 17px), calc(100% - 17px) 100%, 0 100%);
    transition: all .3s ease-in-out;

    &:enabled:hover {
      // color: var(--active-green);
      color: rgba(255, 255, 255, 0.75);
      background: rgba(67, 180, 70, 0.85);
    }

    &:disabled {
      color: var(--disabled-btn-color);
      background: var(--disabled-btn-background);
    }
  }
}
