@import "../styles/theme";
@import "../styles/viewports";

div.reviewSwap {
  margin-top: 0;
  padding: 40px;
  background-color: var(--background);
  color: var(--text);

  @include breakpoint(sm) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: hidden;
  }

  @include breakpoint(xs) {
    padding-left: 16px;
    padding-right: 16px;
  }

  h3 {
    font-family: $font-family;
    color: var(--text);
    margin-top: 0;
    margin-bottom: 32px; // ?24
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  .swapTable {
    margin-bottom: 20px;

    @include breakpoint(sm) {
      flex: 1 1 0;
    }

    .floatRight {
      float: right;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: var(--text);
        font-weight: 500 !important;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;
        text-transform: uppercase;

        @include breakpoint(sm) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .value {
        display: flex;
        align-items: center;
        color: var(--text);
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        letter-spacing: 0.4px;

        &.gap-4 {
          gap: 4px;
        }
      }
    }

    svg.stepArrow {
      margin-right: 16px;
      stroke: var(--outline);
    }

    .aside {
      font-size: 11px;
    }

    .grey {
      color: var(--text-lighter);
    }

    img.arrowDown {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-left: auto;
      float: right;
    }

    .tokenIconWrapper {
      display: flex;
      align-items: center;
    }
    .tokenValueWrapper {
      display: flex;
      align-items: center;
      color: var(--text);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
    }

    img.tokenIcon {
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }

    span.tokenName {
      display: inline-flex;
      align-items: center;
      width: 55%;
      color: var(--text);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
    }

    .divider {
      background-color: var(--divider);
      margin: 16px 0 16px 0;
      height: 1px;
      width: 100%;
    }

    .swapInfo {
      .priceTable {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
  
        .label-s {
          display: flex;
          align-items: center;
          gap: 4px;
  
          .title,
          .pair {
            margin-bottom: 0;
            color: var(--text);
            font-weight: 500 !important;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
          }
  
          .title {
            display: flex;
            align-items: center;

            @include breakpoint(sm) {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
  
        .value {
          display: flex;
          align-items: center;
          color: var(--text);
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.4px;
  
          &.gap-4 {
            gap: 4px;
          }
        }
      }
    }
  }

  .bottom {
    background: var(--background-lightgrey);
    margin: 0 -40px -40px -40px;
    padding: 16px 40px 24px 40px;
    color: var(--text);

    p {
      margin-top: 16px;
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px !important;
      letter-spacing: 0.4px;
      color: var(--text);
      opacity: .5;
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-between;

      button {
        min-width: 170px;

        @include breakpoint(sm) {
          min-width: 140px;
        }

        @include breakpoint(xs) {
          min-width: 90px;
          font-size: 12px;
        }

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  .free-space {
    height: 32px;
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
    width: 16px;
  }
}
