@import "../styles/theme";
@import "../styles/viewports";

div.homepage {
    /* SECTION 1 */
	.section1 {
		padding-top: 80px;
		// min-height: 899px;
		min-height: 100vh;
		background-image: url('../assets/img/fg2.png'),
						  linear-gradient(to right, rgba(0, 17, 0, 1) 0, rgba(0, 84, 0, 1) 85%, rgba(0, 64, 0, 1) 100%);
		background-repeat: no-repeat;
		background-size: 722px 755px,
						 cover;
		background-position: 75% 81px, // 75% 55%
							 center;

		@include breakpoint(mm) {
			padding-top: 60px;
		}
		
		@include breakpoint(xm) {
			background-size: 485px 507px,
							 cover;
			background-position: -18% 9%,
								 center;
		}
		
		.wrapper-top {
			position: relative;
			max-width: 970px;
			height: calc(100vh - 80px - 424px);
			margin: 109px auto 0 auto;

			@include breakpoint(lg) {
				height: auto;
			}

			@include breakpoint(mm) {
				height: calc(100vh - 80px - 109px);
				margin: 49px auto 0 auto;
			}

			.info {
				.title {
					// max-width: 533px; // need for short text only
					font-weight: 700;
					font-size: 70px;
					line-height: 85px;
					letter-spacing: -0.5px;
					color: #fff;

					@include breakpoint(sm) {
						max-width: 533px;
						font-weight: 700;
						font-size: 40px;
						line-height: 49px;
						letter-spacing: -0.5px;
						color: #fff;
					}
				}

				.desc {
					margin-top: -16px;
					max-width: 461px;
					font-weight: 500;
					font-size: 24px;
					line-height: 29px;
					color: #fff;

					@include breakpoint(sm) {
						margin-top: 0;
						font-size: 20px;
						line-height: 24px;
					}
				}
			}

			.action {
				@include breakpoint(mm) {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
				}

				.btn {
					display: inline-block;
					margin-top: 40px;
					padding: 12px 46px 12px 24px;
					border: 0;
					background-color: rgba(255, 255, 255, 0.1);
					font-family: $font-family;
					font-size: 15px;
					line-height: 18px;
					letter-spacing: 1.25px;
					text-transform: uppercase;
					text-decoration: none;
					color: #fff;
					cursor: pointer;
					clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
					transition: all .3s ease-in-out;

					@include breakpoint(mm) {
						width: 100%;
						text-align: center;
					}

					&:hover {
						color: rgba(255, 255, 255, 0.75);
						background: rgba(67, 180, 70, 0.85);
					}
				}
			}
		}

		.wrapper-bottom {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: stretch;
			max-width: 1176px;
			margin: 88px auto 0 auto;

			@include breakpoint(mm) {
				padding-bottom: 32px;
				margin-top: 0;
			}

			.stats {
				display: flex;
				align-items: stretch;
				justify-content: space-between;
				order: 0;

				@include breakpoint(lg) {
					flex-wrap: wrap;
				}

				@include breakpoint(mm) {
					order: 1;
				}

				.card {
					// min-width: calc(33.33333% - 24px);
					width: calc(33.33333% - 24px);
					max-width: 376px;
					max-width: 376px;
					min-height: 143px;
					padding: 2px;
					background: linear-gradient(to bottom, rgba(255, 255, 255, .3) 0, rgba(255, 255, 255, 0) 100%);
					box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
					clip-path: polygon(0 0, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0 100%);

					@include breakpoint(lg) {
						min-width: 100%;
						margin-bottom: 24px;
					}

					@include breakpoint(xm) {
						margin-bottom: 16px;
						min-height: auto;
					}

					&:last-child {
						@include breakpoint(lg) {
							margin-bottom: 0;
						}
					}

					.title {
						margin-bottom: 14px;
						font-size: 16px;
						line-height: 20px;
						text-align: center;
						letter-spacing: 0.5px;
						color: #fff;

						@include breakpoint(xm) {
							margin-bottom: 8px;
						}
					}

					.value {
						font-size: 32px;
						line-height: 39px;
						text-align: center;
						letter-spacing: 0.25px;
						color: #fff;

						@include breakpoint(xm) {
							font-size: 28px;
							line-height: 34px;
						}
					}
				}

				.card-internal {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center; // space-between
					height: 100%;
					padding: 34px 32px;
					background: rgba(0, 36, 1, .68); /* #004302 */
					box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
					clip-path: polygon(0 0, 100% 0, 100% calc(100% - 32px), calc(100% - 33px) 100%, 0 100%);
				}
			}

			.arrow-b {
				display: flex;
				align-items: center;
				justify-content: center;
				order: 1;

				@include breakpoint(mm) {
					order: 0;
				}
		
				img {
					height: 34px;
					width: 19px;
					margin: 20px 0 32px 0;
				}
			}
		}
	}

	/* SECTION 2 */
	.section2 {
		min-height: 533px;
		padding: 104px 0;
		background-color: var(--background);
		transition: background-color .3s ease-in-out;

		@include breakpoint(xm) {
			min-height: auto;
			padding: 80px 0;
		}
		
		.title {
			max-width: 557px;
			margin: 0 auto 31px auto;
			font-size: 48px;
			font-weight: 500;
			line-height: 59px;
			text-align: center;
			color: var(--text);
			transition: color .3s ease-in-out;
	
			@include breakpoint(xm) {
				font-size: 40px;
				line-height: 49px;
			}
		}

		.desc {
			max-width: 795px;
			margin: 0 auto;
			font-size: 24px;
			line-height: 35px;
			// text-align: center; // need for one paragraph only
			color: var(--text-lighter);
			transition: color .3s ease-in-out;
	
			@include breakpoint(xm) {
				font-size: 20px;
				line-height: 35px;
			}
		}
	}

	/* SECTION 3 */
	.section3 {
		background-image: linear-gradient(to right, rgba(0, 17, 0, 1) 0, rgba(0, 84, 0, 1) 85%, rgba(0, 64, 0, 1) 100%);

		.wrapper {
			display: flex;
			max-width: 1176px;
			margin: 0 auto;
			padding: 94px 0 101px 0;
	
			@include breakpoint(lg) {
				flex-direction: column;
			}

			@include breakpoint(xm) {
				padding: 80px 0;
			}

			.graphics {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50%;
				min-width: calc(50% - 4.59%);
				margin-left: -4.59%; // 54px
				margin-right: 4.59%; // 54px
		
				@include breakpoint(lg) {
					width: 100%;
					margin-bottom: 80px;
					margin-left: 0;
					margin-right: 0;
				}
		
				img {
					max-height: 531px;
					max-width: 527px;

					@include breakpoint(xxl) {
						max-height: 477px;
						max-width: 474px;
					}

					@include breakpoint(xl) {
						max-height: 425px;
						max-width: 422px;
					}

					@include breakpoint(lg) {
						max-height: 352px; // 100%
						max-width: 350px; // 100%
					}

					@include breakpoint(xs) {
						max-height: 95%; // 100%
						max-width: 95%; // 100%
					}
				}
			}

			.info {
				width: 50%;
				padding-left: 12px;
		
				@include breakpoint(lg) {
					width: 100%;
					padding-left: 0;
				}
		
				.title {
					margin: 0;
					max-width: 533px;
					font-weight: 500;
					font-size: 48px;
					line-height: 59px;
					color: #fff;
			
					@include breakpoint(xm) {
						font-size: 40px;
						line-height: 49px;
					}
				}
		
				.list {
					@include breakpoint(xm) {
						margin-top: 80px;
					}

					.item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 48px;
				
						@include breakpoint(xm) {
							flex-wrap: wrap;
							margin-top: 64px;
						}
		
						.item-info {
							width: 55%;
					
							@include breakpoint(xm) {
								width: 100%;
							}
					
							.item-title {
								margin: -3px 0 24px 0;
								font-weight: 500;
								font-size: 32px;
								line-height: 39px;
								letter-spacing: 0.25px;
								color: #fff;
								text-decoration: underline;
								text-decoration-color: #43b446;
								text-underline-offset: 3px;
						
								@include breakpoint(xm) {
									font-size: 28px;
									line-height: 34px;
								}
							}
					
							.item-desc {
								font-weight: 500;
								font-size: 16px;
								line-height: 25px;
								letter-spacing: 0.5px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
		
						.item-action {
							width: 181px;
					
							@include breakpoint(xm) {
								margin-top: 48px;
								width: 100%;
							}
					
							.btn {
								display: flex;
								align-items: center;
								justify-content: flex-start;
								width: 100%;
								padding: 12px 24px;
								border: 0;
								background-color: rgba(255, 255, 255, 0.1);
								font-family: $font-family;
								font-size: 15px;
								line-height: 18px;
								letter-spacing: 1.25px;
								text-transform: uppercase;
								color: #fff;
								cursor: pointer;
								clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
								text-decoration: none;
								transition: all .3s ease-in-out;

								@include breakpoint(xm) {
									justify-content: center;
								}

								&:hover {
									color: rgba(255, 255, 255, 0.75);
									background: rgba(67, 180, 70, 0.85);
								}
							}
						}
					}
				}
			}
		}
	}

	/* SECTION 4 */
	.section4 {
		min-height: 639px;

		@include breakpoint(lg) {
			min-height: auto;
		}

		.wrapper {
			display: flex;
			max-width: 1176px;
			margin: 0 auto;
			padding: 104px 0 106px 0;
			border-bottom: 2px solid var(--background);

			@include breakpoint(lg) {
				flex-wrap: wrap;
			}

			.info {
				width: 50%;
				padding-right: 12px;

				@include breakpoint(lg) {
					width: 100%;
					padding-right: 0;
				}

				.info-header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					// max-width: 453px; // need for short text only

					@include breakpoint(lg) {
						// max-width: 100%; // need for short text only
						justify-content: flex-start;
					}

					@include breakpoint(xm) {
						flex-wrap: wrap;
					}

					img {
						height: 104px;
						width: 104px;
						margin-right: 30px;

						@include breakpoint(xm) {
							height: 71px;
							width: 71px;
							margin-bottom: 16px;
						}
					}

					.title {
						margin: 0;
						// max-width: 325px; // need for short text only
						font-weight: 500;
						font-size: 48px;
						line-height: 59px;
						color: var(--text);
						transition: color .3s ease-in-out;

						@include breakpoint(lg) {
							max-width: 100%;
						}

						@include breakpoint(xm) {
							font-size: 40px;
							line-height: 49px;
						}
					}
				}

				.desc {
					margin-top: 32px;
					// max-width: 419px; // need for short text only
					font-size: 24px;
					line-height: 35px;
					color: var(--text-lighter);
					transition: color .3s ease-in-out;

					@include breakpoint(lg) {
						max-width: 100%;
					}

					@include breakpoint(xm) {
						margin-top: 24px;
						font-size: 20px;
						line-height: 24px;
					}
				}

				.action {
					margin-top: 64px;

					@include breakpoint(xm) {
						margin-top: 48px;
					}

					.btn {
						padding: 12px 28px 12px 24px;
						border: 0;
						background-color: var(--btn-bg-light);
						font-family: $font-family;
						font-size: 15px;
						line-height: 18px;
						letter-spacing: 1.25px;
						text-transform: uppercase;
						text-decoration: none;
						color: var(--text);
						cursor: pointer;
						clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
						transition: all .3s ease-in-out;

						@include breakpoint(xm) {
							display: block;
							width: 100%;
							text-align: center;
						}

						&:hover {
							color: rgba(255, 255, 255, 0.75);
							background: rgba(67, 180, 70, 0.85);
						}
					}
				}
			}

			.graphics {
				display: flex;
				align-items: center;
				justify-content: center;
				// width: 50%; // ???
				min-width: calc(50% - 4.59%);
				margin-left: 4.59%; // 54px
				margin-right: -4.59%; // 54px

				@include breakpoint(lg) {
					width: 100%;
					margin: 48px 0;
					margin-left: 0;
					margin-right: 0;
				}

				img {
					max-height: 350px; // 100%
					max-width: 322px; // 100%

					@include breakpoint(lg) {
						max-height: 278px; // 100%
						max-width: 256px; // 100%
					}

					@include breakpoint(sm) {
						max-height: 214px; // 100%
						max-width: 196px; // 100%
					}
				}
			}
		}
	}

	/* SECTION 5 */
	.section5 {
		min-height: 256px;

		.wrapper {
			max-width: 1176px;
			margin: 0 auto;
			padding: 104px 0 170px 0;

			@include breakpoint(sm) {
				padding: 104px 0 459px 0; // 170 + 289 (footer)
			}

			.info {
				.title {
					max-width: 557px;
					margin: 0 auto;
					font-weight: 500;
					font-size: 48px;
					line-height: 59px;
					text-align: center;
					color: var(--text);

					@include breakpoint(xm) {
						font-size: 40px;
						line-height: 49px;
					}
				}

				.desc {
					margin: 24px auto 104px auto;
					max-width: 795px;
					font-size: 24px;
					line-height: 35px;
					text-align: center;
					color: var(--text-lighter);

					@include breakpoint(xm) {
						margin: 24px auto 69px auto;
						font-size: 20px;
						line-height: 35px;
					}
				}
			}

			.graphics {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin: -12px;

				a {
					text-decoration: none;
				}

				& > * {
					margin: 12px;
				}
			}
		}
	}
}