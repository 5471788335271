@import "../styles/theme.scss";

div.radio {
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  label.radio_wrapper {
    position: relative;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    &.disabled {
      cursor: not-allowed;
    }

    .radio_control {
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 2px solid var(--text-lighter);
      transition: border 1s ease-in-out;
      opacity: .4;
      transition: all 1s ease-in-out;
    }
    .radio_input {
      display: grid;
      grid-template-areas: "radio";

      > * {
        grid-area: radio;
      }

      > input {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        opacity: 0;

        &:checked + .radio_control {
          background: radial-gradient(
            circle at 50% 50%,
            var(--active-green) 40%,
            transparent 60%
          );
          transition: all .3s ease-in-out;
          border: 2px solid var(--active-green);
          opacity: 1;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    span.label {
      position: relative;
      margin-top: -4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: var(--text);
      transition: color 1s ease-in-out;
    }
  }
}
