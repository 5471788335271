@import "../styles/theme";
@import "../styles/viewports";

div.poolsPage {
  min-height: 100vh;
  background: var(--background); // new
  color: var(--text);
  font-size: 16px;
  padding-bottom: 104px;
  transition: background 1s ease-in-out;

  @include breakpoint(md) {
    padding-bottom: 143px;
  }

  @include breakpoint(xm) {
    padding-bottom: 367px;
  }

  .filterTab {
    width: max-content;
    padding: 2px 16px;
    border-radius: 50px;
    cursor: pointer;
    font-weight: $medium;

    &.selected {
      background-color: var(--tab);
      color: var(--primary-background);
      font-weight: $bold;
    }
  }

  .outdated {
    color: $yellow;

    &.selected {
      background-color: $yellow;
      color: $text-black;
    }
  }

  .contentInnerWrapper {
    max-width: 776px;
    margin: 0 auto;
    padding-top: 184px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(lg) {
      padding-top: 138px;
    }

    @include breakpoint(sm) {
      margin-left: -16px;
      margin-right: -16px;
    }

    @include breakpoint(xm) {
      padding-top: 104px;
    }

    .title {
      width: 100%;
      margin: 0 0 8px 0;
      font-family: $font-family !important;
      font-weight: 600 !important;
      font-size: 32px;
      line-height: 39px;
      letter-spacing: 0.25px;
      color: var(--text);
      transition: color .3s ease-in-out;

      @include breakpoint(sm) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .desc-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      @include breakpoint(xm) {
        flex-wrap: wrap;
      }

      .desc {
        margin: 0 0 48px 0;
        font-family: $font-family !important;
        font-weight: 500 !important;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: var(--text-lighter);
        transition: color .3s ease-in-out;
  
        @include breakpoint(sm) {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  &.unsupportedNetwork {
    display: flex;
    flex-direction: column;

    .content {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
    }

    h3 {
      font-family: $font-family;
      font-size: 23px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      color: var(--text);
      opacity: .2;
      transition: color 1s ease-in-out;

      @include breakpoint(sm) {
        font-size: 16px;
      }
    }
  }
}
