@import "../styles/theme";
@import "../styles/viewports";

div.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  padding-top: 4%; /* Location of the box */

  @include breakpoint(sm) {
    padding-top: 0;
  }
}

div.modalContent {
  width: 565px;
  min-height: 217px; // 332 - for transaction's list displaying
  margin: auto;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: calc(100vh - 72px);
  overflow-y: auto;
  background-color: var(--background);
  color: var(--text);
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 28px), calc(100% - 28px) 100%, 0 100%);

  @include breakpoint(sm) {
    width: 100%;
    max-height: 100%;
    clip-path: none;
  }

  .close {
    position: absolute;
    right: 0;
    color: var(--outline-gray);
    font-size: 32px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 16px;
    margin-right: 24px;

    @include breakpoint(sm) {
      position: fixed;
    }

    &:hover,
    &:focus {
      color: var(--active-green);
      text-decoration: none;
      cursor: pointer;
      transition: color .3s ease-out;
    }
  }
}

.modalContent::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.modalContent::-webkit-scrollbar-track {
  background: var(--primary-background);
  transition: background 1s ease-in-out;
}
.modalContent::-webkit-scrollbar-thumb {
  background: var(--active-green);
  border-bottom-right-radius: 8px;
  transition: background 1s ease-in-out;
}

body.dark .modalContent::-webkit-scrollbar-track {
  background: var(--primary-background);
}
body.dark .modalContent::-webkit-scrollbar-thumb {
  background: var(--active-green);
}