@import "../styles/theme";
@import "../styles/viewports";

div.walletStatus {
  display: flex;
  align-items: center;

  .btn {
    padding: 10px 24px 10px 14px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    white-space: nowrap;
    color: #fff;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
  }

  .hasAccount {
    display: flex;
    align-items: center;
    cursor: pointer;

    @include breakpoint(md) {
      margin-right: 24px;
    }

    span.address {
      margin-right: 8px;
      font-family: $font-family;
      font-size: 14px; // ? 15px
      font-weight: 500;
      letter-spacing: .31em;
      color: #fff;

      @include breakpoint(lg) {
        display: none;
      }
    }
  }
  .noAccount {
    @include breakpoint(mm) {
      display: none;
    }

    @include breakpoint(sm) {
      left: 0;
      right: 0;
      width: 100%;
      clip-path: none;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      transform: none;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.75);
      background: rgba(67, 180, 70, 0.85);
      transition: all .3s ease-out;
    }
  }
}
