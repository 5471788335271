@import "../styles/theme";
@import "../styles/viewports";

div.poolInfoCard {
  color: var(--text-darker);
  font-size: 16px;
  font-weight: $normal;

  h4 {
    width: 100%;
    font-family: $font-family;
    margin-top: 24px;
    margin-right: 0;
    margin-bottom: 32px;
    color: var(--text);
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    transition: color 1s ease-in-out;
  }

  div.info {
    display: grid;
    grid-template-rows: auto;
    row-gap: 10px;
    .label {
      margin-right: 8px;
    }
    .underline {
      border-bottom: 1px dotted var(--text);
      transition: all 1s ease-in-out;

      &:hover {
        cursor: help;
      }
    }

    .infoItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .value {
        color: var(--text);
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        letter-spacing: 0.4px;
        transition: color 1s ease-in-out;
      }
    }
  }

  .bottom {
    h4 {
      margin-bottom: 4px;
    }

    & > span {
      color: var(--active-green);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      transition: color 1s ease-in-out;
    }
  }

  .twoColumn {
    margin-top: 16px;
  }

  .tokenList {
    margin-top: 8px;

    display: flex;
    flex-wrap: wrap;

    img {
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }

    .token {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-basis: 50%;
      margin-top: 24px;

      @include breakpoint(xs) {
        flex-basis: 100%;
      }

      &:nth-child(odd) {
        width: 51%;
      }

      span.tokenValue {
        flex-basis: 100%;
        display: block;
        margin-top: 8px;
        color: var(--text);
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        transition: color 1s ease-in-out;
      }
    }

    span.tokenPercent {
      margin-left: 16px;
      font-weight: $medium;
    }
  }

  .bold {
    color: var(--text);
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    transition: color 1s ease-in-out;
  }

  .underline {
    border-bottom: 1px dotted var(--text-lighter);
    transition: border-bottom 1s ease-in-out;
  }
}
