// // ----- SF Pro Text -----
// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-Light.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-Light.woff') format('woff'),
//       url('../assets/fonts/SFProText-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-Bold.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-Bold.woff') format('woff'),
//       url('../assets/fonts/SFProText-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-Heavy.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-Heavy.woff') format('woff'),
//       url('../assets/fonts/SFProText-Heavy.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-BoldItalic.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-BoldItalic.woff') format('woff'),
//       url('../assets/fonts/SFProText-BoldItalic.ttf') format('truetype');
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-HeavyItalic.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-HeavyItalic.woff') format('woff'),
//       url('../assets/fonts/SFProText-HeavyItalic.ttf') format('truetype');
//   font-weight: 900;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-LightItalic.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-LightItalic.woff') format('woff'),
//       url('../assets/fonts/SFProText-LightItalic.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-Medium.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-Medium.woff') format('woff'),
//       url('../assets/fonts/SFProText-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-SemiboldItalic.woff') format('woff'),
//       url('../assets/fonts/SFProText-SemiboldItalic.ttf') format('truetype');
//   font-weight: 600;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-Semibold.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-Semibold.woff') format('woff'),
//       url('../assets/fonts/SFProText-Semibold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-Regular.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-Regular.woff') format('woff'),
//       url('../assets/fonts/SFProText-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-MediumItalic.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-MediumItalic.woff') format('woff'),
//       url('../assets/fonts/SFProText-MediumItalic.ttf') format('truetype');
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../assets/fonts/SFProText-RegularItalic.woff2') format('woff2'),
//       url('../assets/fonts/SFProText-RegularItalic.woff') format('woff'),
//       url('../assets/fonts/SFProText-RegularItalic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

// // ----- Roboto -----
// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-Bold.woff') format('woff'),
//       url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-Medium.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-Medium.woff') format('woff'),
//       url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-MediumItalic.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-MediumItalic.woff') format('woff'),
//       url('../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-BoldItalic.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-BoldItalic.woff') format('woff'),
//       url('../assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-LightItalic.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-LightItalic.woff') format('woff'),
//       url('../assets/fonts/Roboto-LightItalic.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-BlackItalic.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-BlackItalic.woff') format('woff'),
//       url('../assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
//   font-weight: 900;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-Regular.woff') format('woff'),
//       url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-Thin.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-Thin.woff') format('woff'),
//       url('../assets/fonts/Roboto-Thin.ttf') format('truetype');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-Light.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-Light.woff') format('woff'),
//       url('../assets/fonts/Roboto-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-Italic.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-Italic.woff') format('woff'),
//       url('../assets/fonts/Roboto-Italic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-Black.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-Black.woff') format('woff'),
//       url('../assets/fonts/Roboto-Black.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Roboto';
//   src: url('../assets/fonts/Roboto-ThinItalic.woff2') format('woff2'),
//       url('../assets/fonts/Roboto-ThinItalic.woff') format('woff'),
//       url('../assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

// ----- Montserrat -----
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

// ----- Icomoon -----
@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?haeebq');
  src:  url('../assets/fonts/icomoon.eot?haeebq#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?haeebq') format('truetype'),
    url('../assets/fonts/icomoon.woff?haeebq') format('woff'),
    url('../assets/fonts/icomoon.svg?haeebq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-book:before {
  content: "\e91f";
}
.icon-telegram:before {
  content: "\ea95";
}
.icon-twitter:before {
  content: "\ea96";
}

