@import "../styles/theme";
@import "../styles/viewports";

div.reviewWithdraw {
  margin-top: 0;
  padding: 40px;
  background-color: var(--background);
  color: var(--text);

  @include breakpoint(sm) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: hidden;
  }

  @include breakpoint(xs) {
    padding-left: 16px;
    padding-right: 16px;
  }

  h3 {
    font-family: $font-family;
    color: var(--text);
    margin-top: 0;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  .table {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      flex: 1 1 0;
    }

    .eachToken {
      width: 100%;
      margin-bottom: 16px;
      display: inline-flex;
      align-items: center;

      div {
        display: inline-flex;
        align-items: center;
      }

      img {
        height: 32px;
        width: 32px;
        margin-right: 8px;
        vertical-align: text-bottom;
      }

      & > .value {
        width: calc(45% - 16px);
        margin-right: 16px;
        color: var(--text);
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px
      }
      & > .token {
        display: inline-flex;
        align-items: center;
        width: 55%;
        color: var(--text);
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }

    .withdrawInfoItem {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > .label {
        display: flex;
        align-items: center;
        width: 45%;
        color: var(--text);
        font-weight: 500 !important;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
      }

      & > .value {
        width: 55%;
        color: var(--text);
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;
      }
    }

    div.rates {
      display: grid;
      grid-template-rows: auto;
      row-gap: 8px;

      span {
        display: block;
      }
    }
  }

  div.bottom {
    background: var(--background-lightgrey);
    margin: 0 -40px -40px -40px;
    padding: 16px 40px 24px 40px;
    color: var(--text);

    p {
      margin-top: 16px;
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px !important;
      letter-spacing: 0.4px;
      color: var(--text);
      opacity: .5;
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-between;

      button {
        min-width: 170px;

        @include breakpoint(sm) {
          min-width: 140px;
        }

        @include breakpoint(xs) {
          min-width: 90px;
          font-size: 12px;
        }

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  .divider {
    background-color: var(--divider);
    margin: 12px 0 16px 0;
    height: 1px;
    width: 100%;
  }
}
