@import "../styles/theme";
@import "../styles/viewports";

div.myShareCard {
  color: var(--text);
  font-size: 16px;
  transition: color 1s ease-in-out;

  h4 {
    width: 100%;
    font-family: $font-family;
    margin-top: 7px;
    margin-right: 0;
    margin-bottom: 32px;
    color: var(--text);
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    transition: color 1s ease-in-out;
  }

  div.info {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    row-gap: 8px;
  }

  .poolShare {
    width: 100%;
  }

  .poolShare span {
    color: var(--active-green);
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .infoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .value {
      color: var(--text);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      transition: color 1s ease-in-out;
    }
  }

  div.currency {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    @include breakpoint(sm) {
      grid-gap: 16px;
    }

    .tokenName,
    .tokenValue {
      display: block;
      color: var(--text);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      transition: color 1s ease-in-out;
    }
  }

  .bold {
    color: var(--text);
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    transition: color 1s ease-in-out;
  }
}
