@import "../styles/theme";

.large {
  font-size: 20px;
  font-weight: $bold;
  align-self: center;
  padding: 2px 4px;
}
.warning {
  color: rgb(240, 142, 27);
  border-left: 1px solid rgb(240, 142, 27);
  background-color: rgba(240, 142, 27, 0.2);
}
.error {
  color: rgb(245, 53, 53);
  border-left: 1px solid rgb(245, 53, 53);
  background-color: rgba(245, 53, 53, 0.2);
}
.small {
  align-self: center;
  display: inline-block;
  line-height: 16px;

  display: flex;
  align-items: center;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);

  padding: 4px 8px 4px 7px;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.disabled {
  color: var(--text-lighter) !important;
  border-left: 1px solid var(--text-lighter);
  background-color: rgba(#A2A3A4, 0.2) !important;
}

.primary {
  color: var(--active-green);
  border-left: 1px solid var(--active-green);
  background-color: rgba(#43B446, 0.2);
}

.absolute {
  position: absolute;
}

.absoluteTop0Left0 {
  position: absolute;
  top: 0;
  left: 1px;
}