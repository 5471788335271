@import "../styles/theme.scss";
@import "../styles/viewports";

div.deposit {
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 104px;
  box-sizing: border-box; // Make the padding included in height calculation
  background: var(--background);
  transition: background 1s ease-in-out;
  color: var(--text);

  @include breakpoint(md) {
    padding-bottom: 143px;
  }

  @include breakpoint(xm) {
    padding-bottom: 367px;
  }

  .contentInnerWrapper {
    max-width: 888px; // 776
    margin: 0 auto;
    padding-top: 184px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 16px;
    align-items: start;

    @include breakpoint(lg) {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding-top: 138px;
    }

    @include breakpoint(sm) {
      margin-left: -16px;
      margin-right: -16px;
    }
  
    @include breakpoint(xm) {
      padding-top: 104px;
    }

    .left,
    .right {
      width: 434px;

      @include breakpoint(lg) {
        margin: 0 auto;
        width: 100%;
      }
    }

    .left {
      .form {
        position: relative;
        width: 434px;
        background: var(--background-lightgrey);
        transition: background 1s ease-in-out;
        box-shadow: var(--shadow);
        padding: 24px;
        box-sizing: border-box;
    
        @include breakpoint(lg) {
          margin: 0 auto;
        }
    
        @include breakpoint(sm) {
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }
    
        &::before {
          content: "";
          position: absolute;
          background: var(--active-green);
          top: 30px;
          left: 0;
          width: 4px;
          height: 32px;
    
          @include breakpoint(sm) {
            left: 0;
          }
        }

        h3 {
          margin-top: 7px;
          width: 100%;
          flex-wrap: nowrap;
          font-family: $font-family;
          margin-bottom: 32px;
          color: var(--text);
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          transition: color 1s ease-in-out;
        }

        div.wrappedDeposit {
          display: flex;
          align-items: center;
          margin-top: 24px; // 32px
      
          > span {
            margin-left: 16px;
            font-family: $font-family;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.4px;
            margin-left: 16px;
            text-transform: uppercase;
            color: var(--text);
            transition: color 1s ease-in-out;
          }
        }
      
        div.transactionInfo {
          margin-top: 32px;
          display: grid;
          grid-template-rows: auto;
          row-gap: 8px;
      
          .transactionInfoItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
      
          span.value {
            color: var(--text-darker);
            font-weight: $bold;
          }
      
          span.bonus,
          span.slippage {
            color: var(--text);
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            transition: color 1s ease-in-out;
          }
      
          span.value.bonus {
            display: inline-flex;
            padding-left: 16px;
            color: var(--text);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            letter-spacing: 0.4px;
            transition: color 1s ease-in-out;
          }
      
          span.value.slippage {
            color: $error;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            letter-spacing: 0.4px;
            transition: color .3s ease-in-out;
          }
      
          a {
            color: var(--acrive-green);
          }
        }

        .error {
          font-family: $font-family;
          width: 100%;
          color: $error;
          margin: 8px;
          text-align: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }
      
        .formSpace {
          width: 100%;
          height: 16px;
        }
      }

      .advancedOptionsWrapper {
        width: 434px;
    
        @include breakpoint(lg) {
          margin: 0 auto;
        }
    
        @include breakpoint(sm) {
          width: 100%;
        }
      }

      div.mainActionBtn {
        width: 434px;
        margin: 2.5rem auto;
        padding: 0 24px;
    
        @include breakpoint(sm) {
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }
    
        button {
          @include breakpoint(xs) {
            width: 100%;
          }
        }
      }
    }

    .right {
      .infoPanels {
        position: relative;
        width: 434px;
        padding: 24px;
        background: var(--background-lightgrey);
        transition: background 1s ease-in-out;
        box-sizing: border-box;
        box-shadow: var(--shadow);
    
        @include breakpoint(lg) {
          margin: 0 auto;
        }
    
        @include breakpoint(sm) {
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }
    
        &::before {
          content: "";
          position: absolute;
          background: var(--active-green);
          top: 30px;
          left: 0;
          width: 4px;
          height: 32px;
    
          @include breakpoint(sm) {
            left: 0;
          }
        }

        .divider {
          width: 100%;
          height: 1px;
          background: var(--divider);
          transition: background 1s ease-in-out;
          margin: 16px 0;
        }
      }
    }
  }
}
