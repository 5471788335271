@import "../styles/theme";
@import "../styles/viewports";

header.top {
  position: fixed; // absolute
	top: 0;
	left: 0;
  z-index: 10;
	width: 100%;
	min-height: 80px;
  background-image: linear-gradient(to right, rgba(0, 17, 0, 1) 0, rgba(0, 84, 0, 1) 85%, rgba(0, 64, 0, 1) 100%);
  transition: top 0.2s ease 0s;

  @include breakpoint(mm) {
    min-height: 60px;
  }

  &.hidden {
    top: -80px;

    @include breakpoint(mm) {
      top: -60px;
    }
  }

  .container {
    height: 80px;

    @include breakpoint(mm) {
      height: 60px;
    }
  }

  .nav-b {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .menu-btn {
    display: none;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;

    @include breakpoint(mm) {
      display: flex;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }

  .logoWrapper {
    order: 0;
    display: flex;
    align-items: center;
    height: 80px;
    padding-right: 64px;

    @include breakpoint(el) {
      flex-grow: unset;
      flex-basis: unset;
    }

    @include breakpoint(mm) {
      flex-grow: unset;
      flex-basis: unset;
      height: 60px;
    }

    @include breakpoint(sm) {
      padding-right: 24px;
    }

    & > a {
      line-height: 0;
    }

    .logo {
      background-image: var(--logo);
      background-repeat: no-repeat;
      background-position: left;
      background-size: contain;
      height: 32px; // Logo: 20; Logo2: 32
      width: 106px; // Logo: 156; Logo2: 106
      display: inline-block;

      @include breakpoint(sm) {
        height: 23px; // Logo: 15; Logo2: 23
        width: 75px; // Logo: 117; Logo2: 75
      }

      // @include breakpoint(xs) {
      //   width: 72px; // Logo: 113; Logo2: 72
      // }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .menu {
    font-family: $font-family;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
    order: 1;
    display: flex;
    align-items: center;
    height: 80px;

    @include breakpoint(mm) {
      display: none;
    }

    a {
      display: inline;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }

      color: #fff;
      transition: color 1s ease-in-out;
      text-decoration: none !important;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 1.25px;

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
        font-weight: 600;
        color: var(--active-green);
      }
    }
  }
  .walletStatus {
    .hasAccount {
      @include breakpoint(mm) {
        display: none;
      }
    }

    .connection {
      &.success .indicator {
        filter: invert(1);
      }
    }
    img.logo {
      height: 1.5em;
    }
  }
  .walletWrapper {
    flex: 1;
    flex-basis: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 2;


    height: 60px;

    @include breakpoint(mm) {
      flex-basis: 50%;
      order: 1;
    }
  }

  .rewards {
    display: flex;
    align-items: center;

    .rewards-btn {
      display: flex;
      align-items: center;
      height: 24px;
      margin: 0 24px;
      cursor: pointer;

      @include breakpoint(mm) {
        margin: 0;
      }

      img {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }

      .value {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #fff;
      }
    }
  }

  .settings {
    display: flex;
    align-items: center;

    @include breakpoint(mm) {
      display: none;
    }

    & > img {
      height: 24px;
      width: 24px;
      margin: 0 66px 0 35px;
      cursor: pointer;

      @include breakpoint(xl) {
        margin: 0 0 0 35px;
      }
    }
  }
}

.siteSettingsWrapper {
  position: absolute;
  top: 110%;
  right: 0;
  z-index: 2;
  box-shadow: var(--shadow);
  width: 299px;
}

.hidden-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: none;
  width: 100%;
  height: 100vh;

  @include breakpoint(mm) {
    &.open {
      display: block;
    }
  }

  .backdrop {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);

    .menu-b {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 20;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 300px;
      padding: 64px 16px 48px 16px;
      border-top-left-radius: 20px;
      border-radius: 20px 20px 0 0;
      background: #002C02;

      .menu-list {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.closed {
          display: none;
        }

        // override
        .walletStatus {
          margin-bottom: 48px;

          .btn {
            padding: 0;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 1.25px;
            background: transparent;
            color: #fff;
            clip-path: unset;

            &.noAccount {
              display: block !important;
            }
          }

          .hasAccount {
            margin: 0;
            color: #fff;

            span.address {
              display: block;
            }
          }
        }

        .menu {
          display: flex;
          flex-direction: column;
          align-items: center;

          a {
            margin-bottom: 48px;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 1.25px;
            color: #fff;
            text-decoration: none;
          }
        }

        .settings {
          margin-bottom: 64px;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 1.25px;
          color: #fff;
          cursor: pointer;
        }
      }

      .settings-list {
        display: none;
        flex-direction: column;
        width: 100%;

        &.open {
          display: flex;
        }

        .header {
          display: flex;
          margin-bottom: 32px;

          .larrow {
            display: flex;
            align-items: center;
            width: 40px;

            img {
              width: 24px;
              height: 20px;
              margin-right: 16px;
              cursor: pointer;
            }
          }

          .title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 40px);
            margin-right: 40px;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 1.25px;
            color: #fff;
          }
        }

        .siteSettingsMenu {
          margin-bottom: 91px;
          &.containerWrapper {
            width: 100%;

            .container {
              clip-path: unset;
              background: unset; // new
              margin: 0;
              padding: 0;
              width: 100%;
              font-size: 16px;
              position: unset;
              top: unset;
              right: unset;
              color: #fff;

              .header {
                display: none;
              }
            }

            .divider {
              background-color: rgba(223, 226, 229, 0.3);
              transition: unset;
              height: 1px;
              width: 100%;
              margin: 12px 0 16px;
            }

            .sectionTitle {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 12px;
              cursor: pointer;
              font-size: 12px;
              line-height: 15px;
              letter-spacing: 0.4px;
              color: rgba(255, 255, 255, 0.8);
              text-transform: uppercase;
            }

            .sectionItem {
              display: flex;
              justify-content: flex-start;
              border-radius: 0;
              padding: 0 !important;
              margin-top: 12px !important;
              margin-bottom: 0 !important;
              margin-left: 0 !important;
              margin-right: 0 !important;
              cursor: pointer;

              &:hover {
                background-color: transparent;
              }

              .logo {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                width: 20px;
                margin-right: 9px;
                background-color: #fff;
                border-radius: 50%;

                &.transparent {
                  background-color: transparent;

                  path {
                    fill: #ffffff;
                  }
                }

                img {
                  height: 20px;
                  width: 20px;
                }
              }

              .text {
                display: flex;
                align-items: center;
                width: calc(100% - 20px - 9px - 14px);
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.5px;
                color: #fff;
              }

              .is-active {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  height: 14px;
                  width: 14px;
                }
              }
            }
          }
        }
      }

      .close-menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: rgba(255,255,255,.1);
        cursor: pointer;

        img {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}
