@import "../styles/theme";
@import "../styles/viewports";

div.confirmTransaction {
  margin-top: 0;
  padding: 32px;
  text-align: center;
  background: var(--background-lightgrey);

  @include breakpoint(sm) {
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: hidden;
  }

  @include breakpoint(xs) {
    padding-left: 16px;
    padding-right: 16px;
  }

  h3 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 24px;
    line-height: 29px;
    color: var(--text);
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  svg {
    margin-top: 32px;
    margin-bottom: 32px;

    background-color: var(--background-lightgrey);
    border-radius: 240px;

    &.loader {
      margin-top: 16px;
      margin-bottom: 20px;
    }
  }
}
