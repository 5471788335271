@import "../styles/theme.scss";
@import "../styles/viewports";

.myFarm {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 27px 24px 24px 24px;
  width: 100%; // 434px
  background: var(--background-lightgrey);
  transition: background 1s ease-in-out;
  box-shadow: var(--shadow);
  box-sizing: border-box;

  @include breakpoint(lg) {
    margin: 0 auto 24px;
  }

  @include breakpoint(sm) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @include breakpoint(xs) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
  }

  &::before {
    content: "";
    position: absolute;
    background: var(--active-green);
    top: 30px;
    left: 0;
    width: 4px;
    height: 32px;
  }

  .farm-title {
    order: 0;
    flex-basis: 50%;
    padding: 0 16px 0 0 !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    letter-spacing: 0 !important;

    @include breakpoint(mm) {
      flex-basis: 40%;
    }

    @include breakpoint(md) {
      flex-basis: 100%;
    }

    &.lock-fit {
      flex-basis: 35%;

      @include breakpoint(mm) {
        flex-basis: 100%;
      }
    }

    h4 {
      flex-wrap: nowrap;
      width: 100%;
      margin: 4px 0 0 0;
      font-family: $font-family;
      color: var(--text);
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      transition: color 1s ease-in-out;

      @include breakpoint(mm) {
        margin: 4px 0 11px 0;
      }
    }

    .addLiquidity-top-w {
      display: block;
      margin-top: 0;
      margin-bottom: -24px;

      @include breakpoint(mm) {
        display: none;
      }
    }

    &.lock-fit {
      .addLiquidity-top-w {
        margin-bottom: -18px;
      }
    }
  }

  .addLiquidity-bottom-w {
    flex-basis: 100%;
    order: 2;
    display: none;
    margin-top: -12px;
    padding-bottom: 12px;

    @include breakpoint(mm) {
      display: block;
      order: 3;
      margin-top: 12px;
      margin-bottom: -8px;
      padding-bottom: 0;
    }

    @include breakpoint(md) {
      order: 1;
      border-bottom: 1px solid var(--divider);
      padding-bottom: 12px;
      margin-bottom: 0;
    }
  }

  .addLiquidity-top-w,
  .addLiquidity-bottom-w {
    a.addLiquidity {
      display: inline-block;
      box-sizing: border-box;
      color: var(--active-green);
      text-decoration: none;
      font-size: 12px;
      font-family: $font-family;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 15px !important;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid var(--active-green);
      }
    }
  }

  .poolInfo,
  .statInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-basis: 50%;
    order: 1;
    width: 50%;
    padding-bottom: 4px; // ???

    @include breakpoint(mm) {
      flex-basis: 60%;
      width: auto;
    }

    @include breakpoint(md) {
      flex-basis: 100%;
      justify-self: start;
      justify-content: space-between;
      order: 2;
      width: 100%;
      margin-top: 20px; // 24px
    }

    @include breakpoint(md) {
      flex-wrap: wrap;
    }

    &.lock-fit {
      flex-basis: 65%;

      @include breakpoint(mm) {
        flex-basis: 100%;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 24px;
      }

      @include breakpoint(md) {
        flex-basis: 100%;
        justify-content: space-between;
        margin-top: 4px;
        margin-bottom: 0;
      }
    }

    img.tokenIcon {
      height: 20px;
      width: 20px;
      margin-right: 6px;
      margin-top: -4px;
    }

    .margin {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      min-width: 117px;
      padding: 0 21px 0 25px; // 24 + 1 = 25
      transition: border-left 1s ease-in-out;

      @include breakpoint(md) {
        margin-right: auto;
      }

      @include breakpoint(xs) {
        flex-basis: 50%;
        margin-bottom: 16px;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 7px;

        @include breakpoint(xs) {
          margin-bottom: 0;
        }
      }

      &::before {
        content: "";
        position: absolute;
        background: var(--divider);
        top: 9px;
        left: 0;
        width: 1px;
        height: 24px;
      }

      span.label {
        display: block;
        color: var(--text-lighter);
        font-weight: 500;
        font-size: 12px;
        line-height: 15px !important;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        transition: color 1s ease-in-out;
        white-space: nowrap !important;
      }

      span.value {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        color: var(--text);
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.0015em;
        text-transform: uppercase;
        transition: color 1s ease-in-out;

        &.percents {
          color: var(--active-green);
          white-space: nowrap;
        }
      }
    }

    &.lock-fit {
      .margin {
        @include breakpoint(md) {
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
    }
  }

  .decoration-divider {
    flex-basis: 100%;
    order: 2;
    margin-top: 24px;
    height: 1px;
    background-color: var(--divider);

    @include breakpoint(mm) {
      order: 4;
      margin-top: 16px;
    }
  }

  .tokens {
    display: flex;
    flex-direction: row;
    flex-basis: calc(100% - (48px + (176px * 3)));
    align-items: flex-end;
    order: 3;

    @include breakpoint(lg) {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }

    @include breakpoint(mm) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      flex-basis: 100%;
      order: 2;
    }

    @include breakpoint(md) {
      order: 1;
      border-bottom: 1px solid var(--divider);
      padding-bottom: 16px;
    }

    .token {
      margin-right: 32px;
      display: flex;
      align-items: center;
      color: var(--text);
      font-weight: 500;
      font-size: 12px;
      line-height: 15px !important;
      letter-spacing: 0.4px;
      transition: color 1s ease-in-out;

      @include breakpoint(lg) {
        margin-top: 8px;
      }

      @include breakpoint(mm) {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      img.tokenIcon {
        height: 24px;
        width: 24px;
        margin-right: 8px;
        border-radius: 50%;
      }
    }
  }

  .list {
    display: flex;
    justify-content: space-between;
    flex-basis: calc((159px + 25px) * 3);
    order: 4;
    margin-top: 24px;

    @include breakpoint(mm) {
      flex-basis: 100%;
    }

    @include breakpoint(md) {
      display: block;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; // flex-start
      flex-direction: column;
      flex-basis: 33.33333%;
      padding-left: 25px;
      text-align: right;

      @include breakpoint(mm) {
        padding-left: 12px;
        padding-right: 12px;

        &:first-child {
          margin-left: -12px;
        }

        &:last-child {
          margin-right: -12px;
        }
      }

      @include breakpoint(md) {
        flex-basis: unset;
        flex-direction: row;
        margin-bottom: 32px; // 16px
        text-align: left;

        padding-left: 0;
        padding-right: 0;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      @include breakpoint(sm) {
        max-width: 100%;
        width: 100%;
      }

      &.xs-wrap {
        @include breakpoint(xs) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &.claimDisabled {
        opacity: .4;
        pointer-events: none;
      }

      &:last-child {
        @include breakpoint(md) {
          display: flex;
          align-items: center;
          margin-bottom: 0;
        }

        @include breakpoint(xs) {
          flex-wrap: wrap;
          align-items: flex-start;
          gap: 8px;
        }
      }

      .value-w {
        width: 100%;

        .value-info {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .item-label {
            color: var(--text-lighter);
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.4px;
            transition: color 1s ease-in-out;
          }

          .balance {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: -3px;
            margin-left: 16px;
            margin-bottom: 4px;

            .bold {
              color: var(--text);
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              text-align: right;
              letter-spacing: 0.5px;
              transition: color 1s ease-in-out;

              @include breakpoint(md) {
                margin-bottom: 0;
              }

              &.usd {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                text-align: right;
                letter-spacing: 0.4px;
                text-transform: uppercase;
                opacity: 0.2;
                color: var(--text);
                transition: color 1s ease-in-out;

                @include breakpoint(md) {
                  margin-top: 0;
                  margin-bottom: 0;
                }
              }

              .claim-all.tokenIcon {
                display: inline-block;
                height: 20px;
                width: 20px;
                margin: -3px 0 0 8px;
              }

              &-small {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: right;
                letter-spacing: 0.4px;
              }
            }
          }
        }

        .lock-info-w {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
          width: 100%;
          height: 46px;

          .balance {
            margin-bottom: 0;
          }
        }

        .farmInput {
          position: relative;
          margin-bottom: 24px;
          background: var(--input-bg);
          border-left: 4px solid var(--active-green);
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 21px), calc(100% - 21px) 100%, 0 100%);
          transition: background 1s ease-in-out;

          @include breakpoint(md) {
            margin-top: 6px;
            margin-bottom: 0;
          }

          @include breakpoint(xs) {
            margin-bottom: 12px;
          }

          input {
            width: 100%;
            height: 46px !important;
            padding-left: 12px;
            padding-right: 64px;
            font-family: $font-family !important;
            font-size: 18px !important;
            color: var(--text);
            background: var(--input-bg);
            font-weight: 500;
            font-size: 18px !important;
            line-height: 22px !important;
            letter-spacing: 0.0015em;
            transition: all 1s ease-in-out;
            outline: 0;
            border: 0;

            &[disabled] {
              opacity: 0.5;
            }
          }

          &.errorSum {
            border-left: 4px solid $error;
            transition: border-left .3s ease-in-out;

            input {
              color: $error;
              transition: color .3s ease-in-out;
            }
          }

          button.max-btn {
            position: absolute;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 15px;
            right: 16px;
            bottom: 16px;
            padding: 0;
            color: var(--active-green);
            font-family: $font-family !important;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            border: 0;
            cursor: pointer;
            transition: color 0.3s ease;

            &[disabled] {
              pointer-events: none;
              opacity: .5;
            }
          }
        }
      }

      &.lock-b {
        @include breakpoint(md) {
          flex-wrap: wrap;

          .value-w {
            flex-basis: calc(100% - 156px);
          }
        }

        .lockedStakeExitInfo {
          display: none;
          padding: 16px 0;
          font-family: $font-family;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px !important;
          color: var(--active-green);
          letter-spacing: 0.4px;

          @include breakpoint(md) {
            display: flex;
            align-items: center;
            flex-basis: 100%;
          }

          img {
            height: 16px;
            width: 16px;
            margin-top: -3px;
            margin-right: 8px;
          }
        }
      }

      .btn-w {
        position: relative;
        width: 100%;

        button.button {
          width: 100%;
          justify-content: flex-start;
        }

        @include breakpoint(md) {
          width: auto;
          padding-left: 16px;
          margin-bottom: 4px;
        }

        @include breakpoint(xs) {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding-left: 0;
          margin-bottom: 0;
        }

        button.outline {
          @include breakpoint(md) {
            width: 140px;
          }

          @include breakpoint(xs) {
            justify-content: center;
            width: 100%;
          }
        }

        .toolTipBox {
          position: absolute;
          top: calc(50% - 8px);
          right: 16px;
          width: 16px;
          height: 16px;

          @include breakpoint(md) {
            display: none;
          }

          img {
            margin-left: 0;
          }

          .tooltipText {
            text-align: left;

            &::after {
              left: calc(50% - 6px);
            }
          }
        }
      }
    }
  }

  .partnershipWith {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    order: 4;
    margin: 24px 0 -12px;
    padding-top: 15px;
    color: var(--text);
    font-family: $font-family;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px !important;
    letter-spacing: 0.4px;
    transition: color 1s ease-in-out;
    border-top: 1px solid var(--divider);

    .slogan {
      text-align: center;
    }

    img {
      height: 24px;
      margin-left: 12px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: var(--text);
      transition: color 1s ease-in-out;
      cursor: pointer;

      &:hover {
        .slogan {
          margin-bottom: -1px;
          border-bottom: 1px solid var(--text);
        }
      }
    }
  }
}
