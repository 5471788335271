@import "../styles/theme";
@import "../styles/viewports";

div.connectWallet {
  font-family: $font-family-title;
  color: var(--text);
  padding: 40px;
  background: var(--background);

  @include breakpoint(sm) {
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: hidden;
  }

  @include breakpoint(xs) {
    padding-left: 16px;
    padding-right: 16px;
  }

  & > h3 {
    font-family: $font-family;
    color: var(--text);
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  div.walletList {
    display: grid;
    grid-template-rows: auto;
    row-gap: 16px;
    padding: 32px 0;

    & > button {
      background: var(--background);
      color: var(--text);
      outline: none;
      border: 2px solid var(--outline-gray);
      border-radius: 10px;
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: $bold;

      &:hover {
        cursor: pointer;
        color: var(--active-green);
        border-color: var(--active-green);
        transition: all .3s ease-out;
      }
    }

    img.icon {
      width: 24px;
    }

    span {
      color: var(--text);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;

      span.grey {
        margin-left: 8px;
        font-weight: normal;
        font-size: 14px;
        color: var(--text-lighter);
      }
    }
  }

  p {
    padding: 24px 32px 32px 32px;
    margin: -24px;
    background: var(--background);
    border-radius: 8px;

    font-weight: 500;
    font-size: 14px; // 12px
    line-height: 15px !important;
    color: var(--text);
    letter-spacing: 0.4px;

    a {
      color: var(--active-green);
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// FIX WALLETLINK
.-walletlink-css-reset {
  .-walletlink-extension-dialog {
    @include breakpoint(sm) {
      position: absolute !important;
      top: 24px !important;
      align-items: unset !important;
    }
  }

  .-walletlink-extension-dialog-box {
    max-width: 100% !important;
  
    @include breakpoint(md) {
      max-width: 90% !important;
    }
  
    @include breakpoint(sm) {
      max-width: 95% !important;
  
      .-walletlink-extension-dialog-box-top {
        flex-direction: column;
        align-items: center;
  
        .-walletlink-extension-dialog-box-top-install-region {
          align-items: center;
          h2 {
            text-align: center;
          }
        }
  
        .-walletlink-extension-dialog-box-top-info-region {
          padding-bottom: 8px;
        }
      }
    }
  
    @include breakpoint(xs) {
      .-walletlink-extension-dialog-box-bottom {
        flex-direction: column;
        align-items: center;
  
        .-walletlink-extension-dialog-box-bottom-description-region {
          align-items: center;
          padding-bottom: 0;
  
          h2,
          .-walletlink-extension-dialog-box-bottom-description {
            text-align: center;
          }
        }
      }
    }
  
    .-walletlink-extension-dialog-box-top-description-text,
    .-walletlink-extension-dialog-box-bottom-description {
      min-width: unset !important;
    }
  }
}

// FIX WALLETCONNECT
.walletconnect-modal__mobile__toggle_selector {
  background-color: #8a8e97 !important;
}
