/* Breakpoint sizes */
$screen-xxs-max: 375px; // 375
$screen-xs-max: 440px; // 440
$screen-xm-max: 500px;
$screen-sm-max: 575px; // 544
$screen-md-max: 767px; // 768
$screen-mm-max: 869px;
$screen-lg-max: 991px; // 1024
$screen-xl-max: 1199px;
$screen-xxl-max: 1399px;
$screen-el-max: 1799px;

/* Create the mixin */
@mixin breakpoint($media) {
  @if $media == xxs {
    @media only screen and (max-width: $screen-xxs-max) {
      @content;
    }
  } @else if $media == xs {
    @media only screen and (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $media == xm {
    @media only screen and (max-width: $screen-xm-max) {
      @content;
    }
  } @else if $media == sm {
    @media only screen and (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $media == mm {
    @media only screen and (max-width: $screen-mm-max) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (max-width: $screen-lg-max) {
      @content;
    }
  } @else if $media == xl {
    @media only screen and (max-width: $screen-xl-max) {
      @content;
    }
  } @else if $media == xxl {
    @media only screen and (max-width: $screen-xxl-max) {
      @content;
    }
  } @else if $media == el {
    @media only screen and (max-width: $screen-el-max) {
      @content;
    }
  }
}

/*
utilize the mixin like this:

@include breakpoint(<xs, sm, md, or lg>) {
    <selector>: <value>;
}
*/


/* INTERNAL GRID-WRAPPER (START) */
.container {
  margin: 0 66px;
}

/* el */
@include breakpoint(el) {
   .container {
    margin: 0 66px;
  }
}
/* xxl */
@include breakpoint(xxl) {
  .container {
    margin: 0 66px;
  }
}
/* xl */
@include breakpoint(xl) {
  .container {
    margin: 0 66px;
  }
}
/* lg */
@include breakpoint(lg) {
  .container {
    margin: 0 48px;
  }
}
/* mm */
@include breakpoint(mm) {
  .container {
    margin: 0 32px;
  }
}
/* md */
@include breakpoint(md) {
  .container {
    margin: 0 24px;
  }
}
/* sm */
@include breakpoint(sm) {
  .container {
    margin: 0 16px;
  }
}
/* xm */
@include breakpoint(xm) {
  .container {
    margin: 0 16px;
  }
}
/* xs */
@include breakpoint(xs) {
  .container {
    margin: 0 16px;
  }
}
/* INTERNAL GRID-WRAPPER (FINISH) */