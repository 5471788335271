/**
* GLOBAL STYLES
* Imported once at the top level
* Used everywhere
*/
@import "fonts";
@import "theme";

* {
  box-sizing: border-box;
}

p,
b,
span {
  font-family: $font-family;
  line-height: 20px;
  // size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title;
}

svg,
img {
  // TODO: get rid of this once chakra is more integrated
  display: inline !important;
}

ul {
  list-style: none;
}

:root {
  --footer-bg: #f6f8f9;
  --footer-color: #333;
  --background: #F2F5F6; // new
  --text: #333; // new
  --text-lighter: #9A9D9F; // new
  --text-lighter2: #787F87; // new
  --btn-bg-light: rgba(67, 180, 70, 0.06);
  --background-lightgrey: #fff; // new
  --background-lightgrey-hover: #f9f9f9;
  --input-bg: #F6F8FA;
  --input-subtext-opacity: 0.2;
  --divider: #DFE2E5;
  --shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  --secondary-button-active: rgba(67, 180, 70, 0.06); // new
  --disabled-btn-color: #BDC1C7;
  --disabled-btn-background: rgba(162, 163, 164, 0.1);
  // ------------------------------------------------

  --disabled-btn-border: #A2A3A4;
  // --disabled-btn-background: rgba(162, 163, 164, 0.1);
  // --background-notification: rgba(162, 163, 164);
  --underline-opacity: 0.2;
  --active-green: #43B446;
  --primary-foreground: #000;
  --primary-background: #fff;
  // --logo: url("../assets/img/logo-dark.svg"); // new
  --logo: url("../assets/img/logo2.svg"); // new
  // --background: #F2F5F6; // new
  --foreground: #16191C; // new
  // --background-lightgrey: rgb(255, 255, 255); // new
  --background-element: rgba(255, 255, 244, 0.7);
  --background-element-hover: rgba(255, 255, 255, 0.88);
  --background-logo: #{$primary};
  --secondary-button: rgba(162, 163, 164, 0.1); // new
  // --secondary-button-active: rgba(67, 180, 70, 0.1); // new
  --secondary-button-active-max: rgba(67, 180, 70, 0.2); // new
  --emphasis-1: #{$sand2}; // passive state
  --emphasis-2: #{$sand1}; // active state
  --primary-accent: #{$primary-blue};
  --outline: #000;
  --outline-gray: #{$black30};
  --option-hover: #{$primary-blue-light};
  --tab: #{$black80};

  // --text: #16191C; // new
  // --text: #333; // new
  --text-darker: #000;
  // --text-lighter: rgba(22, 25, 28, 0.8); // new
  --text-lightest: #a5a5a5;
  --text-title: #{text-black};
  --text-primary: #{$primary-blue};
  --text-selected: #{$primary-blue};
  --text-secondary-button: #{$primary-blue};
  --text-secondary-button-disabled: #{$indigo20T};
  --header-text: #1f2023;
  --sign-graph: #000;

  // --shadow: 0px 2px 8px rgba(39, 14, 1, 0.1);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.2);
}

// Dark mode themes
body.dark {
  --footer-bg: #202427;
  --footer-color: #fff;
  --background: #16191C; // new
  --text: #fff;
  --text-lighter: rgba(255, 255, 255, 0.8); // new
  --text-lighter2: #787F87; // new
  --btn-bg-light: #43B446; // active-green
  --background-lightgrey: #202427; // new
  --background-lightgrey-hover: #23272a; // rgba(255,255,255,.01)
  --input-bg: #16191C;
  --input-subtext-opacity: 0.8;
  --divider: rgba(223, 226, 229, 0.4);
  --shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  --secondary-button-active: rgba(67, 180, 70, 0.1); // new
  --disabled-btn-color: #BDC1C7;
  --disabled-btn-background: rgba(162, 163, 164, 0.1);
  // ------------------------------------------------

  --underline-opacity: 1;
  --primary-foreground: #fff;
  --primary-background: #000;
  --logo: url("../assets/img/logo2.svg"); // new
  // --background: #16191C; // new
  --foreground: #F2F5F6; // new
  // --background-lightgrey: rgb(32, 36, 39); // new
  --background-element: rgba(0, 0, 0, 0.56);
  --background-element-hover: rgba(0, 0, 0, 0.8);
  --background-logo: #ffffe9;
  --secondary-button: rgba(162, 163, 164, 0.1); // new
  // --secondary-button-active: rgba(67, 180, 70, 0.1); // new
  --secondary-button-active-max: rgba(67, 180, 70, 0.2); // new
  --emphasis-1: #{$indigo3}; // passive state
  --emphasis-2: #{$indigo2}; // active state
  --primary-accent: #{$primary};
  --outline: #fff;
  --option-hover: #{$primary-blue-dark};
  --outline-gray: #{$white30};
  --tab: #{$white};

  // --text: #fff;
  --text-darker: #ffffe9;
  // --text-lighter: rgb(162, 163, 164); // new
  --text-lightest: #888888;
  --text-title: #ffffe9;
  --text-primary: #{$primary};
  --text-selected: #{$primary};
  --text-secondary-button: #{$blue};
  --text-secondary-button-disabled: #{$blue40T};
  --header-text: #{$primary};
  --sign-graph: #{$primary-blue};

  // --shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);
}
