@import "../styles/theme";

.checkboxInput {
  cursor: pointer;
  width: 24px;
  height: 24px;

  .checkboxControl {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid var(--foreground);
    background-color: var(--background);
    transition: all 1s ease-in-out;
  }

  .checkboxControl > svg {
    margin: 0;
    width: 20px;
    height: 20px;
    stroke: var(--active-green);
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
    display: block !important;
  }

  & > input {
    width: 24px;
    height: 24px;

    display: none;
    &:checked + .checkboxControl svg {
      transform: scale(1);
    }
  }
}
