@import "../styles/theme";
@import "../styles/viewports";

.accountDetail {
  padding: 16px;

  @include breakpoint(sm) {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }

  .upperSection {
    background: var(--background);
    padding: 16px 24px 24px 24px;
  
    @include breakpoint(xs) {
      padding-left: 16px;
      padding-right: 16px;
    }

    h3.accountTitle {
      margin-top: 0;
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: var(--text);
      font-family: $font-family;
    }

    .accountControl {
      display: flex;
    
      @include breakpoint(xm) {
        flex-direction: column;
      }
    
      .left,
      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
    
        @include breakpoint(xm) {
          width: 100%;
        }
    
        .label {
          height: 20px;
          margin-bottom: 8px;
          color: var(--text-lighter);
          font-weight: 500;
          font-size: 12px;
          line-height: 15px !important;
          letter-spacing: 0.4px;
          transition: color 1s ease-in-out;
        }
    
        .data {
          display: flex;
          align-items: center;
          height: 26px;
          margin-bottom: 24px;
          color: var(--text);
          font-weight: 700;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 0.4px;
    
          @include breakpoint(xm) {
            margin-bottom: 8px;
          }

          span.address {
            margin-left: 8px;
            margin-right: 4px;
            letter-spacing: 2.4px;
          }
        }
    
        .buttonGroup {
          display: flex;
          align-items: center;
          height: 20px;

          .textStyle {
            display: flex;
            align-items: center;
            color: var(--active-green);
            font-weight: 600;
            outline: none;

            svg {
              margin-right: 8px;
              fill: var(--active-green);
            }
          }
        }
      }
    
      @include breakpoint(xm) {
        .left {
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(40, 48, 55, var(--underline-opacity));;
        }
    
        .right {
          padding-top: 16px;

          .buttonGroupIcon {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .lowerSection {
    background: var(--background-lightgrey);
    padding: 24px;
    min-height: 136px;
  }
}
