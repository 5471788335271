@import "../styles/theme";
@import "../styles/viewports";

div.swapPage {
  min-height: 100vh;
  background: var(--background);
  color: var(--text);
  font-size: 14px;
  font-family: $font-family;
  line-height: 1.25;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background 1s ease-in-out, color 1s ease-in-out;
  padding-bottom: 104px;

  @include breakpoint(md) {
    padding-bottom: 143px;
  }

  @include breakpoint(xm) {
    padding-bottom: 367px;
  }

  h3.swapTitle {
    margin: 0 16px 8px 0;
    color: var(--text);
    font-size: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    transition: color 1s ease-in-out;
  }

  .balanceContainer {
    margin-left: 8px;
  }

  button:hover {
    cursor: pointer;
  }

  .free-space {
    position: absolute;
    top: 27px; // initial: 20px (balance displaying case)
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;
    width: 16px; // initial: 21px (balance displaying case)
    height: 31px;

    svg {
      width: 16px;
      height: 31px;
    }
  }

  .swapForm {
    width: 100%;
    background: var(--background-lightgrey); // new
    padding: 24px;
    transition: background 1s ease-in-out;
    box-shadow: var(--shadow);

    @include breakpoint(sm) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .to-swap-input {
      margin-bottom: 32px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: baseline;
    }
    .balanceContainer {
      span,
      a {
        color: var(--text);
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;
        transition: color 1s ease-in-out;
      }
      a.balance {
        font-weight: 700;
        color: var(--active-green); // new
        font-size: 12px;

        &:hover {
          cursor: pointer;
          border-bottom: 1px dotted var(--active-green);
          transition: border-bottom .3s ease-in-out;
        }
      }
    }

    .disabledInput {
      pointer-events: none;
      cursor: crosshair;
      opacity: .4;
    }
  }

  .contentInnerWrapper {
    margin: 0 auto;
    padding-top: 184px;
    max-width: 776px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    @include breakpoint(lg) {
      padding-top: 138px;
    }

    @include breakpoint(sm) {
      margin-left: -16px;
      margin-right: -16px;
    }

    @include breakpoint(xm) {
      padding-top: 104px;
    }

    .title {
      width: 100%;
      margin: 0 0 8px 0;
      font-family: $font-family !important;
      font-weight: 600 !important;
      font-size: 32px;
      line-height: 39px;
      letter-spacing: 0.25px;
      color: var(--text);
      transition: color .3s ease-in-out;

      @include breakpoint(sm) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .desc {
      width: 100%;
      margin: 0 0 48px 0;
      font-family: $font-family !important;
      font-weight: 500 !important;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: var(--text-lighter);
      transition: color .3s ease-in-out;

      @include breakpoint(sm) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  span.exchange {
    display: inline-block;
    height: 24px;
    width: 24px;
    cursor: pointer;

    &:hover {
      transform: none;
    }

    svg {
      margin-bottom: -6px;
      margin-left: 16px;
    }
  }

  .description {
    margin: 10px 0 0 0;

    .label {
      color: var(--text);
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      transition: color 1s ease-in-out;
    }

    .value {
      display: inline-flex;
      padding-left: 16px;
      color: var(--text);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      letter-spacing: 0.4px;
      transition: color 1s ease-in-out;
    }
  }

  .virtual-swap-link a {
    color: var(--active-green) !important;
    padding-bottom: 0;
    border-bottom: 1px solid var(--active-green);
  }

  a.virtual-swap-link {
    color: var(--text);
    padding-bottom: 0;
    border-bottom: 1px solid var(--text);
  }

  .virtualSwapInfoBubble {
    padding: 8px 16px;
    margin-top: 16px;
    background: rgba($blue-info, 0.2);
    width: 100%;
    font-size: 14px;
    line-height: 1.3;
    border: 1px solid $blue-info;
    > svg {
      fill: var(--primary-foreground);
      margin-right: 8px;
    }
  }

  .exchangeWarning {
    color: var(--text);
    background-color: rgba($text-red, 0.2);
    padding: 8px 16px;
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    border: 1px solid $text-red;
    transition: all .3s ease-in-out;
  }

  .infoSection {
    width: 100%;
    display: flex;
    margin-top: 32px;
    .title {
      cursor: pointer;
      svg {
        margin-left: 10px;
        transform: norotate(0deg);
        &.upsideDown {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .advancedOptions {
    width: 100%;
    margin-bottom: 32px;

    .tableContainer {
      display: none;
      &.show {
        display: block;
      }
    }

    .table {
      width: 100%;
      background: var(--background-element);
      margin-top: 16px;
      border: 1px solid var(--outline);
      border-radius: 10px;
      padding: 16px 24px;

      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .parameter {
        margin: 2px 0;
      }
      .parameter .options {
        margin-top: 10px;
      }
    }
  }

  .error {
    width: 100%;
    color: $error;
    text-align: center;
    margin-top: -15px;
    display: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .info {
    width: 100%;
    text-align: center;
    margin-top: 8px;
    display: none;
  }
  .showInfo,
  .showError {
    display: block;
  }

  .pendingSwaps {
    margin-top: 16px;
    width: 100%;
    > .pendingSwapItem {
      width: 100%;
      border: 1px solid $primary-blue;
      border-radius: 10px;
      background: var(--primary-background);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      cursor: pointer;
      margin-bottom: 16px;

      .swapDetails {
        color: $primary-blue;
        font-weight: 700;
        font-size: 16px;
      }

      .swapTimeContainer {
        display: flex;
        align-items: center;
        > svg {
          margin-right: 10px;
        }
        span.time {
          font-size: 16px;
        }
      }
    }
  }

  .mainActionBtn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 8px 0 48px;
    padding: 0 24px;

    @include breakpoint(sm) {
      padding-left: 16px;
      padding-right: 16px;
    }

    button {
      @include breakpoint(xs) {
        width: 100%;
        padding-right: 24px !important;
      }
    }
  }

  .no-pointer {
    cursor: default !important;
    &:hover {
      border-bottom: 0 !important;
    }
  }
}
