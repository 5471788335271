@import "../styles/theme";

.swapInputContainer {
  width: 100%;
  position: relative;
}

.swapInputContainerInner {
  height: 68px;
  border-left: 4px solid var(--active-green); // new
  display: flex;
  position: relative;
  background-color: var(--background); // new
  transition: all 1s ease-in-out;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 24px), calc(100% - 24px) 100%, 0 100%);

  &.errorInput {
    border-left: 4px solid $error !important;
    transition: border-left .3s ease-in-out;
  }
}

.selectGroup {
  display: flex;
  align-items: center;
  padding-left: 20px;

  &.hoverPointer {
    &:hover {
      cursor: pointer;
    }
  }
  > img.asset-logo {
    height: 32px;
    width: 32px;
    margin-right: 8px;
  }

  .tokenNameContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // margin-right: 10px;

    .asset-desc {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: var(--text);
      opacity: var(--input-subtext-opacity);
      transition: all .3s ease-in-out;
    }
  }
  // .symbolArrowContainer {
  //   display: flex;
  //   align-items: center;

  //   // img.arrow {
  //   //   display: block;
  //   //   height: 24px;
  //   //   width: 24px;
  //   //   margin-left: 16px;
  //   // }

  //   // svg path {
  //   //   fill: var(--primary-foreground);
  //   // }
  // }

  img.arrow {
    display: block;
    height: 24px;
    width: 24px;
    margin-left: 16px;
    margin-right: 8px;
    cursor: pointer;
  }
}

.inputGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  cursor: default;

  input {
    height: 29px;
    margin: 13px 0 0 0;
    padding-right: 24px;
    border: 0;
    font-family: $font-family;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    cursor: default;
    outline: none;
    text-align: right;
    background-color: transparent;
    color: var(--text); // new
    width: 100%;
    transition: background 1s ease-in-out, color 1s ease-in-out;

    &.errorInput {
      color: $error;
    }
  }
  &.focusable,
  &.focusable input {
    cursor: text;
  }
}

.textBoldAsset {
  outline: none;
  color: var(--text); // new
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  // margin-right: 10px;
  transition: color 1s ease-in-out;
}

.dropdownContainer {
  width: 100%;
  position: absolute;
  top: 110%;
  left: -1px;
  z-index: 2;
  filter: drop-shadow(var(--shadow));
}

.textMinor {
  margin: 0 24px 14px 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  opacity: var(--input-subtext-opacity);
  color: var(--text);
  transition: all 1s ease-in-out;
}
