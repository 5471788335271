@import "../styles/theme.scss";
@import "../styles/viewports";

.advancedOptions {
  width: 100%;
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;

  @include breakpoint(sm) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .titleWrapper {
    overflow: hidden;

    .title-b {
      cursor: pointer;
  
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: var(--text);

      letter-spacing: 0.4px;
      position: relative;
      transition: all 1s ease-in-out;
  
      &:before {
        content: '';
        position: absolute;
        background: var(--divider);
        left: calc(100% + 6px);
        bottom: 7px;
        height: 1px;
        width: 100vw;
        transition: all 1s ease-in-out;
      }
  
      img {
        height: 24px;
        width: 24px;
        margin: 0 8px -8px 16px;
      }
    }
  }

  .tableContainer {
    display: none;

    div.parameterInfiniteApproval {
      margin-top: 16px;
    }
  
    &.show {
      display: block;
    }
  
    .parameter {
      margin: 2px 0;
  
      &.underlined {
        border-bottom: 1px solid var(--divider);
        transition: border-bottom 1s ease-in-out;
      }
    }
    .parameter .options {
      margin: 12px 0;
    }
  
    .inputGroup {
      .options {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        & > button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          background: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: var(--text);
          transition: color 1s ease-in-out;
          letter-spacing: -0.04em;
          cursor: pointer;
    
          &:hover {
            transform: none;
            color: var(--text);
            transition: color .3s ease-in-out;
          }
    
          &:focus {
            outline: none;
            border: none;
          }
    
          &.selected {
            color: var(--active-green);
            padding: 0;
            font-weight: 600;
          }
    
          @include breakpoint(xs) {
            font-size: 14px;
          }
        }

        input {
          width: 48px;
          height: 28px;
          color: var(--text);
          padding: 0 5px;
          outline: none !important;
          background: var(--background-lightgrey);
          border-left: 2px solid var(--active-green);
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
          transition: all 1s ease-in-out;
        }
    
        input:focus {
          outline: 1px solid var(--outline);
        }
    
        .label {
          width: calc((100% - 85px) / 3);
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: var(--text);
          transition: color 1s ease-in-out;
          margin: auto 16px auto 0;
          position: relative;
    
          @include breakpoint(xs) {
            font-size: 10px;
          }
        }

        .customValue {
          width: 80px;
    
          input {
            margin: 0 !important;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.4px;
            text-align: left;
            color: var(--text);
            transition: all 1s ease-in-out;
          }
    
          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.4px;
            color: var(--text);
            transition: color 1s ease-in-out;
          }
        }
      }
    }
  }
}

.infiniteApproval {
  color: var(--text);
  position: relative;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  transition: color 1s ease-in-out;

  .label {
    position: relative;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
    margin-left: 16px;
    text-transform: uppercase;
    color: var(--text);
    transition: color 1s ease-in-out;

    &:hover {
      cursor: help;
    }
  }
}
