@import "../styles/theme";

.unsupportedNetwork {
  margin-bottom: 4px;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: var(--text);
  opacity: .2;
  transition: color 1s ease-in-out;
}

.switchToCronosNetwork {
  margin-bottom: 24px;
  font-family: $font-family;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.08em;
  color: var(--text);
  opacity: .2;
  transition: color 1s ease-in-out;
}
