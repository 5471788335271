@import "../styles/theme";
@import "../styles/viewports";

/* FOOTER */
footer.footer {
  position: absolute;
  width: 100%;
  bottom: 0;

  min-height: 39px;
  background-color: var(--footer-bg);

  @include breakpoint(md) {
    min-height: 78px;
  }

  @include breakpoint(xm) {
    min-height: 102px;
  }

  @include breakpoint(xs) {
    min-height: auto;
  }

  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1176px;
    min-height: 39px;
    margin: 0 auto;

    @include breakpoint(md) {
      margin-top: 39px;
    }

    @include breakpoint(xm) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      min-height: auto;
      margin: 40px 0 48px 0;
    }

    .expander {
      margin: 0 auto;

      @include breakpoint(xm) {
        display: none;
      }
    }

    .copy {
      margin-right: 59px;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
      color: var(--footer-color);

      @include breakpoint(xm) {
        margin-right: 16px;
      }

      @include breakpoint(xs) {
        flex-basis: unset;
        margin: 8px 0;
      }
    }

    .links {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
      color: var(--footer-color);

      @include breakpoint(xm) {
        margin-top: 8px;
      }

      @include breakpoint(xm) {
        flex-basis: unset;
        margin: 32px 0 48px 0;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .social {
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      @include breakpoint(md) {
        margin-top: -39px;
      }

      @include breakpoint(xm) {
        position: unset;
        margin: 0;
      }

      a {
        display: inline-flex;
        align-items: center;
        margin-right: 16px;
        text-decoration: none;

        @include breakpoint(xm) {
          margin-right: 48px;
        }

        &:last-child {
          margin-right: 0;
        }

        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    .audited {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
      color: var(--footer-color);

      @include breakpoint(xm) {
        flex-basis: unset;
        margin: 48px 0 0 0;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;

        img {
          margin-left: 12px;
          height: 19px;
          width: 65px;
        }
      }
    }
  }
}
