body {
  position: relative;
  min-width: 320px !important;
  margin: 0;
  background: #F2F5F6;
  color: #16191C;
  font-size: 14px;
  /* font-family: 'SF Pro Text', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 1.25;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background 1s ease-in-out, color 1s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-is-open,
body.hiddenMenuIsOpen {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body.dark {
  background: #16191C;
  color: #FFF;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
body::-webkit-scrollbar-track {
  background: rgba(40, 48, 55, 0.2);
  transition: background 1s ease-in-out;
}
body::-webkit-scrollbar-thumb {
  background: #283037;
  border-radius: 4px;
  transition: background 1s ease-in-out;
}

body.dark::-webkit-scrollbar-track {
  background: #283037;
}
body.dark::-webkit-scrollbar-thumb {
  background: #16191C;
}

.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.pointer {
  cursor: pointer !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: capitalize !important;
}

.nowrap {
  white-space: nowrap !important;
}

/* WE3 LOADING INDICATOR (START) */
body .web3-loader-b {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 5px;
  width: 100%;
  /* background-color: rgba(67,180,70,.4); */
  overflow: hidden;
  z-index: 20;
}
body.active-loader .web3-loader-b {
  display: block;

}
.active-loader .web3-loader-b .bar {
  position: relative;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, transparent, var(--active-green), var(--active-green), transparent);
  animation-name: loader-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes loader-animation {
  0% {
     left: -100%;
  }
  50% {
     left: 100%;
  }
  50% {
     left: -100%;
  }
  100% {
     left: 100%;
  }
}
/* WE3 LOADING INDICATOR (FINISH) */

/* LOADING BLINKING (START) */
.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
      opacity: 0;
  }
}
/* LOADING BLINKING (FINISH) */
