@import "../styles/theme";
@import "../styles/viewports";

.tokenClaimModal {
  width: 100%;
  background: var(--background); // new

  .tcmLogoWrapper {
    display: flex;
    justify-content: center;

    .logo {
      height: 170px;
      width: 170px;
      border-radius: 50%;
      margin-top: -85px;
      background: var(--background); // new
      background: linear-gradient(
        0deg,
        rgb(67, 180, 70) 0%,
        var(--background) 40%,
        var(--background) 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;
    
      > img {
        height: 85%;
        width: 85%;
        border-radius: 50%;
      }
    }
  }
  
  .gradient {
    height: 128px;
    width: 100%;
    background: var(--background); // new
    background: linear-gradient(
      0deg,
      rgba(67, 180, 70, 0.5) 0%,
      rgba(67, 180, 70, 0.3) 40%,
      rgba(67, 180, 70, 0) 100%
    );
  }

  .mainContent {
    padding: 32px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    @include breakpoint(sm) {
      padding-left: 24px;
      padding-right: 24px;
    }
  
    @include breakpoint(xs) {
      padding-left: 16px;
      padding-right: 16px;
    }
  
    & > button {
      height: 40px;
      width: 170px;
      padding: 0 32px 0 14px;
      border-radius: 0;
      color: var(--text) !important;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.1em;
      background-color: var(--secondary-button-active-max) !important;
      clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
      text-transform: uppercase;
      letter-spacing: 0.1em;
      transition: color .3s ease-out;
  
      &:disabled {
        background-color: var(--secondary-button) !important;
        cursor: not-allowed !important;
      }
  
      &:enabled:hover {
        color: var(--active-green) !important;
      }
    }

    .tokenBalance {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 48px;
      line-height: 59px;
      font-family: $font-family;
      color: var(--text);

      @include breakpoint(sm) {
        font-size: 40px;
        line-height: 49px
      }

      img {
        margin-left: 16px;
        cursor: pointer;
      }
    }

    .tokenBalanceHelpText {
      font-size: 12px;
      font-family: $font-family;
      padding-bottom: 24px;
      text-transform: uppercase;
      color: var(--text);
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
    }

    .claimIsForbidden {
      text-transform: uppercase;
      color: var(--text);
      font-weight: 600;
      line-height: 1.3;
      font-size: 14px;
      margin-bottom: 24px;
      letter-spacing: 0.08em;
      text-align: center;
    
      .activateDate {
        border-bottom: 2px solid var(--text);
        font-style: italic;
        display: inline-block;
      }
    }
    
    .tokenInfo {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      margin: -16px 0 38px;
      font-family: $font-family;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      border-bottom: 1px solid var(--divider);
    
      .tokenPrice {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        flex-basis: 50%;
        height: 100%;
        padding-right: 16px;
        font-family: $font-family;
        color: var(--text);
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;
        border-right: 1px solid var(--divider);
    
        .tokenPriceValue {
          display: flex;
          align-items: center;
          color: var(--text);
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.5px;
        }
      }
    
      .tokenBuyLink {
        display: flex;
        align-items: center;
        flex-basis: 50%;
        height: 100%;
        padding-left: 16px;
        font-family: $font-family;
        color: var(--text);
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;

        a {
          display: flex;
          align-items: center;
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
    
        img {
          width: 20px;
          height: 20px;
          margin-left: 8px;
          margin-top: -3px;
        }
      }
    }

    .claimsList {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 24px;
      width: 100%;

      .listItem {
        width: 100%;
        padding: 10px 0; // new
        display: flex;
        align-items: center;
        justify-items: flex-start;
    
        @include breakpoint(xs) {
          flex-wrap: wrap;
        }
    
        & > * {
          display: inline-block;
          flex: 1 1 40%;
    
          @include breakpoint(xs) {
            flex: 1 1 50%;
          }
        }
    
        & > b {
          padding-right: 8px;
          color: var(--text);
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          transition: color 1s ease-in-out;
        }
    
        & > span {
          color: var(--text);
          text-transform: uppercase;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.5px;
          transition: color 1s ease-in-out;
    
          @include breakpoint(xs) {
            text-align: right;
          }
        }
    
        :last-child {
          flex: 0 1 20%;
    
          @include breakpoint(xs) {
            flex: 0 1 100%;
          }
        }
      }

      .divider {
        height: 1px;
        width: 100%;
        background-color: var(--divider);
      }
    }

    .info {
      display: block;
      width: 100%;
      margin-top: 0;
      padding-bottom: 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px !important;
      color: var(--text);
      letter-spacing: 0.4px;
      font-family: $font-family;
      text-align: left;
    }
  }
}
