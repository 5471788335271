@import "../styles/theme.scss";
@import "../styles/viewports";

.toolTip {
  display: inline-block;
  position: relative;
  cursor: help; // pointer

  & > .tooltipText {
    visibility: hidden;
    display: block;
    background-color: var(--active-green);
    color: $white;
    border-radius: 4px;
    width: max-content;
    max-width: 360px;
    padding: 12px 16px 16px 16px;
    white-space: pre-wrap;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    font-family: $font-family;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 15px;

    @include breakpoint(sm) {
      left: unset;
      right: unset;
      transform: unset;
      width: calc(100vw - 50%);
      max-width: unset;
    }
  }

  &.rightVariant > .tooltipText {
    @include breakpoint(sm) {
      right: 0;
    }
  }

  /* Tooltip arrow */
  & > .tooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: var(--active-green) transparent transparent transparent;

    @include breakpoint(sm) {
      left: 10%;
    }
  }

  &.rightVariant > .tooltipText::after {
    left: 60%;

    @include breakpoint(sm) {
      left: 80%;
    }
  }

  &:hover > .tooltipText {
    visibility: visible;
  }

  // Control
  span {
    display: flex;
    align-items: center;
  }

  img {
    height: 16px;
    width: 16px;
    margin-left: 8px;
  }
}


.swapPage {
  .toolTip {
    & > .tooltipText {
      @include breakpoint(lg) {
        left: unset;
        right: unset;
        transform: unset;
        width: calc(100vw - 279%);
        max-width: unset;
      }
  
      @include breakpoint(mm) {
        width: calc(100vw - 260%);
      }
  
      @include breakpoint(md) {
        width: calc(100vw - 157%);
      }

      @include breakpoint(sm) {
        width: calc(100vw - 50%);
      }
    }
  }
}
