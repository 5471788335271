@import "../styles/theme";

ul.bn-notify-custom.bn-notify-notifications {
  width: 320px;
}

.bn-notify-custom.bn-notify-notification-info-message {
  font-family: $font-family;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--text);

  a {
    text-decoration: underline;
    color: $blue-info;
    font-weight: 500;
  }
}

.bn-notify-custom.bn-notify-notification-info-meta-timestamp {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.bn-notify-custom.bn-notify-notification-close-icon > svg {
  height: 12px;
  width: 12px;
}

.bn-notify-custom.bn-notify-notification-info-meta-duration {
  display: none;
}

li.bn-notify-custom.bn-notify-notification-pending {
  border-left: 2px solid $blue-info;
  border-radius: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
  background-color: var(--background-element-hover);

  .bn-notify-custom.bn-notify-notification-status-icon {
    #el_PHAWgO26lN,
    #el_A4XF5QQwhp {
      stroke: $blue-info;
    }

    g {
      animation-iteration-count: 0 !important;
    }
  }
}

li.bn-notify-custom.bn-notify-notification-error {
  border-left: 2px solid $error;
  border-radius: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
  background-color: var(--background-element-hover);

  .bn-notify-custom.bn-notify-notification-status-icon {
    #el_bYTVKD04y * {
      animation-duration: 0s;
    }
    #el_5BNAI_PBsn,
    #el_IAuv9ut-2- {
      stroke: $error;
    }

    #el_q_eIK0z3HI {
      fill: $error;
    }
  }

  .bn-notify-custom.bn-notify-notification-info-message {
    a {
      color: $error;
    }
  }
}

li.bn-notify-custom.bn-notify-notification-success {
  #el_3OA8Szq_A * {
    animation-duration: 0s;
  }

  border-left: 2px solid var(--active-green);
  border-radius: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
  background-color: var(--background-element-hover);
  color: var(--text);

  #el_RzYtw9rUyN {
    stroke: var(--active-green);
  }
}

.bn-notify-custom.bn-notify-notification-close-icon {
  &:hover {
    background-color: var(--background-element-hover);
  }
}
